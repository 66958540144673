<template>
	<section class="content">
		<div class="row justify-content-center">
			<div class="card-back">
				<div class="card card-primary card-mx-auto">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="text-big mt-4 mb-3">
                                    Selesaikan Verfikasi Akun
                                </div>
                                <div class="text-origin my-2">
                                    Unggah berkasmu agar dapat menikmati fitur lebih lengkap.
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-4 d-flex justify-content-center">
                                            <img src="@/assets/img/verifikasi-web-icon-1.svg" alt="tarik dana bospay image" width="80%">
                                        </div>
                                        <div class="col-md-10">
                                            <div class="text-heavy my-3">
                                                Dapat melakukan tarik dana bosPAY
                                            </div>
                                            <div class="text-origin my-2">
                                                Unggah berkasmu agar dapat menikmati fitur lebih lengkap.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-4 d-flex justify-content-center">
                                            <img src="@/assets/img/verifikasi-web-icon-2.svg" alt="tarik dana bospay image" width="80%">
                                        </div>
                                        <div class="col-md-10">
                                            <div class="text-heavy my-3">
                                                Keamanan akunmu lebih terjamin
                                            </div>
                                            <div class="text-origin my-2">
                                                Dengan data yang lebih lengkap, akunmu akan lebih aman.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="p-2"></div>
                                <div class="m-auto">
                                    <div class="text-big"><center>Perangkat kamu tidak memiliki kamera?</center></div>
                                    <div class="card-body text-center">
                                        <div class="qrcode my-3">
                                            <qr-code :text="url" :size="200"></qr-code>
                                            <img class="qrcode__image" src="@/assets/img/logo-img.svg" alt="Boscod Logo" />
                                        </div>
                                    </div>
                                </div>
                                <div class="text-origin"><center>Scan QR untuk melanjutkan proses verifikasi data di aplikasi bosCOD.</center></div>
								<br />
                                <div class="separator" style="color: #959595;">&nbsp;atau&nbsp;</div>
								<br />
                                <div class="text-origin"><center>Klik link <a :href="url" target="_blank" rel="noopener noreferrer" style="text-decoration:none; color:#F27136;">disini</a></center></div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Vue from "vue";
import { authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "icheck-material";
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);
// import Dropzone from "dropzone";
// import "dropzone/dist/dropzone.css";

export default {
data() {
	return {
		errors: [],
		loadingContent: false,
		roles: "",
		groups: [],
		bank: [],
		show: false,
		method: "PUT",
		form: {
		id: "",
		bank_id: "",
		bank_rekening: "",
		bank_atasnama: "",
		// file_ktp: "",
		},
			status: "create",
	//	 form_data : "",
		submitting1: false,
		submitting2: false,
        url: '',
	};
},
created: function () {
	this.userInfo = auth.user();
	let id = this.userInfo.id;
	this.loadRekening(id);

	authFetch("/shipper/bank")
	.then((res) => {
		if (res.status === 201) {
		} else if (res.status === 400) {
		}
		return res.json();
	})
	.then((js) => {
		this.bank = js.data;
	});

	authFetch("/shipper/verification/url")
	.then((res) => {
		if (res.status === 201) {
		} else if (res.status === 400) {
		}
		return res.json();
	})
	.then((js) => {
		this.url = js.url;
		$('.loading-overlay').removeClass('show');
	});
},
methods: {
	loadRekening(id) {
		authFetch('/users/profile/rekening').then((res) => {
			res.json().then((json) => {
				if (
					(json.bank_id == null || json.bank_id == 0 || json.bank_id == "") &&
					(json.bank_rekening == "" || json.bank_rekening == null) &&
					(json.bank_atasnama == "" || json.bank_atasnama == null)
				) {
					this.form.id = json.id;
					this.form.bank_id = "";
					this.form.bank_rekening = "";
					this.form.bank_atasnama = "";
				} else {
					this.form.id = json.id;
					this.form.bank_id = json.bank_id;
					this.form.bank_rekening = json.bank_rekening;
					this.form.bank_atasnama = json.bank_atasnama;
					this.status = "update";
					$("#submit").prop("disabled", true);
					if (
						json.bank_idnew != null &&
						json.bank_rekeningnew != null &&
						json.bank_atasnamanew != null
					) {
						this.status = "updated";
						$("#bankid").prop("disabled", true);
						$("#no_rek").prop("readonly", true);
						$("#atasnama").prop("readonly", true);
					}
				}

				if (json.file_path) {
					$("#imgPreview").attr(
						"src",
						window.$apiUrl+"/uploads/" +
						json.file_path +
						"/" +
						json.file_name
					);
				}
			});
		});
	},
	submitForm: function (ev) {
		$("#submit").prop("disabled", true);
		$("#update").prop("disabled", true);
        this.loadingContent = true;
		this.submitting2 = true;
		const e = this.$refs;
		var data = Object.keys(this.form)
			.map(
			(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
			)
			.join("&");
		this.method = "PUT";
		let urlSubmit = this.status != "update" ? "/users/profile/rekening/" + this.form.id : "/payment/saldo/req_change_rek";

		authFetch(urlSubmit, {
			method: this.method,
			body: data,
		})
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.errors = [];
			if (!js.success) {
				console.log(js.details);

				for (var key in js.details) {
					if (js.details.hasOwnProperty(key)) {
						this.errors.push(js.details[key]);
					}
				}

				$("#update").prop("disabled", false);
				$("#submit").prop("disabled", false);

				return;
			} else {
				Swal.fire({
					title: this.status != "update" ? "Update Berhasil" : "Pengajuan Berhasil",
					icon: "success",
					text: this.status != "update" ? "Info rekening sudah diupdate" : "Pengajuan perubahan rekeningmu berhasil disimpan dan menunggu approval admin",
					showCloseButton: true,
				});

				$("#bankid").prop("disabled", true);
				$("#no_rek").prop("readonly", true);
				$("#atasnama").prop("readonly", true);
			}
			this.loadingContent = false;
		});

		ev.preventDefault();
	},
	cancelRequest() {
		this.loadingContent = true;
		Swal.fire({
			title: "Batalkan Pengajuan Rekening?",
			showCancelButton: true,
			confirmButtonText: "Ya",
			cancelButtonText: "Tidak",
		}).then((result) => {
			if (result.isConfirmed) {
				this.method = "PUT";
				let urlSubmit = "/payment/saldo/unreq_change_rek";

				authFetch(urlSubmit, {
					method: this.method
				})
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					if (!js.success) {
						console.log(js.details);

						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}

						return;
					} else {
						Swal.fire({
							title: "Berhasil Membatalkan Pengajuan",
							icon: "success",
							text: "Info rekening batal diajukan",
							showCloseButton: true,
						});

						this.status = "update";
						$("#bankid").prop("disabled", false);
						$("#no_rek").prop("readonly", false);
						$("#atasnama").prop("readonly", false);
					}
					this.loadingContent = false;
				});

				ev.preventDefault();
			} else {
				this.loadingContent = false;
			}
		});
	},
	browsefile: function (argument) {
		document.getElementById("inputFile").click();
	},
	uploadFoto: function (file) {
		// var info = auth.user();
		var file_data = $("#inputFile").prop("files");
		var form_data = new FormData();
		var fileInput = $("#inputFile")[0];
		this.readURL(fileInput);
		$.each(fileInput.files, function (k, file) {
			form_data.append("file", file);
		});
		$("#loading-avatar").show();
		form_data.append("subdir", "/ktp/" + this.form.id);
		form_data.append("id", this.form.id);

		authFetch("/users/profile/upload_ktp", {
			method: "POST",
			body: form_data,
			headers: {
				"Content-Type": null,
			},
		})
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.errors = [];
			if (!js.status) {
				for (var key in js.details) {
					if (js.details.hasOwnProperty(key)) {
						this.errors.push(js.details[key]);
					}
				}

				return;
			}

			$("#loading-avatar").hide();
		});
	},
	readURL: function (input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$("#imgPreview").attr("src", e.target.result);
			};
			reader.readAsDataURL(input.files[0]); // convert to base64 string
		}
	},
	removeAvatar: function () {
		// var info = auth.user();
		authFetch("/shipper/remove_ktp/" + this.form.id, {
			method: "POST",
			headers: {
				"Content-Type": null,
			},
		})
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			$("#img_id").val("");
			$("#img_code").val("");
			$("#imgPreview").attr(
			"src",
			window.$apiUrl+"/uploads/default/unknown_user.jpg"
			);
			$(".user-profile").addClass("empty-profile");
		});
	},
},
components: {
    vSelect
},
mounted() {},
};
</script>
<style type="text/css">
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile+label {
	font-size: 1.25em;
	font-weight: 700;
	color: white;
	/*background-color: black;*/
	display: inline-block;
}

.inputfile+label {
	cursor: pointer;
	/* "hand" cursor */
}

.inputfile:focus+label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.user-profile .center-profile {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 36px;
	height: 36px;
	transition: opacity 0.3s;
	line-height: 34px;
	text-align: center;
	text-decoration: none;
}

.user-profile .btn-profile {
	display: block;
	width: 36px;
	height: 36px;
	transition: opacity 0.3s;
	border-radius: 50%;
	background-color: #fff;
	color: #606975;
	line-height: 34px;
	box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
	cursor: pointer;
}

.user-profile .remove-profile {
	/*visibility: hidden !important;*/
	cursor: pointer;
}

.card-back {
    justify-content: center;
    align-items: center;
    padding: 2px;
    width:52rem;
    left: 492px;
    top: 400px;
    background: transparent;
    border-radius: 5px;
}

.text-big {

    /* Header 1/SemiBold */

    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;

    /* Neutral/Dark-01 */

    color: #202939;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-origin {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Neutral/Dark-01 */

    color: #202939;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.text-heavy {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    /* identical to box height, or 143% */


    /* Neutral/Dark-01 */

    color: #202939;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.qrcode {
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
}

.qrcode__image {
    background-color: #fff;
    border: 0.25rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    height: 20%;
    width: 20%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #DDDDDD;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}
</style>