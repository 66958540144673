<template>
	<section class="content redesign">
		<div v-if="!locationInputMode" class="modal show" tabindex="-1" role="dialog" ref="formSelectPickup" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title font-weight-bold">Pilih Alamat Pengirim</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fi fi-rr-cross-small"></i></button>
					</div>
					<div class="modal-body">
						<div class="row mb-3" style="row-gap: 1rem;">
							<div class="col-sm">
								<button type="button" class="btn btn-sm btn-block btn-outline-warning font-weight-bold h-100 py-3 rounded" v-on:click="addPickup">
									<i class="fi fi-rr-plus"></i> Tambah Alamat Baru
								</button>
							</div>
							<div class="col-sm">
								<button type="button" class="btn btn-sm btn-block btn-outline-warning font-weight-bold h-100 py-3 rounded" v-on:click="editPickup">
									<i class="fi fi-rr-home-location"></i> Kelola Alamat
								</button>
							</div>
						</div>
						<div ref="scrollbar">
							<div class="form-group">
								<div class="input-group">
									<div class="input-group-prepend">
										<div class="input-group-text pr-0 rounded" style="background-color: #fff;"><i class="fi fi-rr-search"></i></div>
									</div>
									<input type="text" class="form-control border-left-0" v-model="formSender.find_seller" placeholder="Cari Alamat" alt="🔎︎" v-on:keyup="findSeller()">
								</div>
							</div>
							<div v-if="!listWarehouse.length" class="form-control mt-3 h-100 py-3">
								<div class="d-flex flex-column" style="gap: 6px">
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
								</div>
							</div>
							<div v-else class="d-flex flex-column overflow-auto" style="max-height: 450px;gap: 1rem;">
								<div v-for="item in listWarehouse">
									<div class="form-control d-flex justify-content-between align-items-center h-100 py-3">
										<div class="d-flex flex-column text-break" style="gap: 6px">
											<div class="font-weight-bold">{{ item.sender_name }}<span class="badge badge-success ml-3" v-if="item.addr_default == 1">UTAMA</span></div>
											<div>{{ item.pic_tel }} ({{ item.pic_name }})</div>
											<div>{{ item.addr_detail }}</div>
											<div>{{ item.kecamatan }}, {{ item.kota }}, {{ item.kodepos }}</div>
                      <div>
                        <div v-if="item.sender_latitude != null && item.sender_longitude != null" class="badge badge-primary">Sudah set lokasi</div>
                        <div v-else class="badge badge-danger">Belum ada lokasi</div>
                      </div>
										</div>
										<button type="button" class="btn btn-sm btn-warning text-light" v-if="item.shipper_address_id != selectedId" v-on:click="onSelect(item.shipper_address_id)">
											Pilih&nbsp;Alamat
										</button>
									</div>
								</div>
							</div>
						</div>
						<div v-if="loading" class="overlay d-flex justify-content-center pt-5"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
					</div>
				</div>
			</div>
		</div>

		<!-- Pesan untuk perlu menambah pin lokasi -->
		<div v-if="locationInputMode" class="modal show" tabindex="-1" role="dialog" ref="formSelectPickup" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-body">
						<img src="../assets/img/location.svg" class="d-block m-auto" />
						<br />
						<h5 style="text-align: center;">
							Kamu harus menambahkan pin lokasi agar dapat menggunakan Lion Parcel
						</h5>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary font-weight-bold py-3 mx-2 w-50" data-dismiss="modal">Kembali</button>
						<button type="button" class="btn btn-primary text-light font-weight-bold py-3 mx-2 w-50" @click="showLocationInputForm">Tambah Sekarang</button>
					</div>
				</div>
			</div>
		</div>

		<!-- form add pickup address per shipper id -->
		<div class="modal" tabindex="-1" role="dialog" ref="formAddPickup" data-backdrop="static">
			<div class="modal-dialog modal-lg" role="document">
				<div v-if="!locationInputMode || locationInputStep == 1" class="modal-content">
					<form role="form" @submit="submitAddPickup">
						<input type="hidden" name="id" id="id" v-model="formSender.id" />
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">{{ formTitle }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fi fi-rr-cross-small"></i></button>
						</div>
            <div class="modal-body" style="max-height: 90vh; overflow-y: auto;">
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="control-label">Label</label>
									<input type="hidden" name="row_id" value="" />
									<input
										id="sender_name"
										class="form-control"
										v-model="formSender.sender_name"
										type="text"
										required="required"
										name="sender_name"
										placeholder="Cth: Toko"
										:readonly="locationInputMode"
										onkeydown="return (event.ctrlKey || event.altKey
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (event.keyCode==191 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
									/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Nama Pengirim</label>
									<input type="hidden" name="row_id" value="" />
									<input
										id="pic_name"
										class="form-control"
										v-model="formSender.pic_name"
										type="text"
										required="required"
										name="pic_name"
										placeholder="Cth: Rajib Wijaya"
										:readonly="locationInputMode"
										onkeydown="return (event.ctrlKey || event.altKey
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (event.keyCode==191 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
									/>
									<small>Bisa disamakan dengan nama pengirim</small>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">No. Telepon</label>
									<input
										id="pic_tel"
										pattern="0[0-9]{7,}|62[0-9]{7,}"
										formnovalidate
										class="form-control"
										v-model="formSender.pic_tel"
										type="text"
										required="required"
										name="pic_tel"
										placeholder="Cth: 085XXXXXXXXX"
										minlength="10"
										maxlength="16"
										:readonly="locationInputMode"
										onkeydown="return (event.ctrlKey || event.altKey
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==32) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46))"
									/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Kecamatan atau Kota</label>
									<v-select
										label="name"
										:filterable="false"
										:options="addrOptions"
										v-model="formSender.addr_id"
										:reduce="(opt) => opt.id"
										required="required"
										:disabled="locationInputMode"
										@search="onSearch"
										@input="setSelected">
										<template slot="no-options"> Cari Kecamatan atau Kota </template>
										<template slot="option" slot-scope="option">
											<div class="d-center">
												{{ option.lokasi }}
											</div>
										</template>
										<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">
												{{ option.lokasi }}
											</div>
										</template>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!formSender.addr_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Alamat Lengkap</label>
									<textarea
										id="addr_detail"
										name="addr_detail"
										class="form-control"
										v-model="formSender.addr_detail"
										required="required"
										minlength="15"
										placeholder="Tulis Alamat Lengkap"
										:readonly="locationInputMode"
										onkeydown="return (event.ctrlKey || event.altKey
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57  && event.shiftKey==true)
										|| (event.keyCode==191 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48  && event.shiftKey==true)
										|| (event.keyCode==190  && event.shiftKey==false) || (event.keyCode==188  && event.shiftKey==false)
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
									></textarea>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label class="control-label">Kode POS</label>
									<input
										id="kodepos"
										name="kodepos"
										class="form-control"
										v-model="formSender.kodepos"
										type="number"
										minlength="5"
										required="required"
									/>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label id="labelCourierBranch" class="control-label">
										CABANG KURIR (KHUSUS JNE)
									</label>
									<v-select
										label="name"
										:options="courierBranchOption"
										v-model="formSender.code"
										:reduce="(opt) => opt.code"
									>
									</v-select>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label class="control-label">ALAMAT JNE</label>
									<v-select
										label="addrName"
										:options="addrJne"
										v-model="formSender.addr_jne"
										:reduce="(opt) => opt.kode"
									>
									</v-select>
								</div>
								<div v-if="locationInputMode" class="col-md-12 form-group">
									<label class="control-label">Lokasi Alamat</label>
									<div class="input-group mb-3">
										<input
											id="senderAddr"
											name="senderAddr"
											class="form-control"
											placeholder="Cari Lokasi Alamat"
											v-model="locationQuery"
											@input="onLocationSearchInput"
											@keydown.enter.prevent="searchMap" />
										<div class="input-group-append">
											<button class="btn btn-outline-secondary dropdown-toggle" ref="mapDropdown" type="button" data-toggle="dropdown" @click="searchMap"><i class="fas fa-search"></i></button>
											<div v-if="loadingLocation" class="dropdown-menu dropdown-menu-right w-100">
												<button class="dropdown-item" type="button" disabled>
													Mencari alamat...
												</button>
											</div>
											<div v-else class="dropdown-menu dropdown-menu-right w-100">
												<button v-for="place in placeList" class="dropdown-item" type="button" @click="selectLocation(place)">
													<b>{{ place.displayName }}</b><br />{{ place.formattedAddress }}
												</button>
												<button v-if="placeList.length === 0 && !loadingLocation" class="dropdown-item" type="button" disabled>
													Lokasi tidak ditemukan
												</button>
											</div>
										</div>
									</div>
								</div>
								<div v-if="locationInputMode" class="col-md-12">
									<GoogleMap ref="googleMap" @mapInitialized="mapInitialized" @centerChanged="selectMap"
										@formattedAddressChanged="updateFormattedAddress" />
									<div class="mt-2" style="min-height: 50px;">
										{{ formattedAddress }}
									</div>
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col">
									<button type="button" class="btn btn-block btn-outline-primary font-weight-bold py-3" v-on:click="closeForm">Kembali</button>
								</div>
								<div class="col">
									<button v-if="locationInputMode" type="button" class="btn btn-block btn-primary text-light font-weight-bold py-3" @click="locationInputStep = 2">Simpan</button>
									<button v-else type="submit" class="btn btn-block btn-primary text-light font-weight-bold py-3">Simpan Alamat</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div v-if="locationInputStep == 2" class="modal-content">
					<div class="modal-body">
						<img src="../assets/img/question.svg" class="d-block m-auto" />
						<br />
						<h5 style="text-align: center;">
							Apakah lokasi yang kamu pilih sudah benar?
						</h5>
						<hr />
						<p style="text-align: center;">
							Pastikan lokasi yang dipilih sudah benar karna perubahan hanya bisa dilakukan oleh tim CS.
						</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary font-weight-bold py-3 mx-2 w-50" @click="locationInputStep--">Kembali</button>
						<button type="button" class="btn btn-primary text-light font-weight-bold py-3 mx-2 w-50" @click="saveAddress">Simpan Perubahan</button>
					</div>
				</div>
				<div v-if="locationInputStep == 3" class="modal-content">
					<div class="modal-body">
						<img src="../assets/img/ok.svg" class="d-block m-auto" />
						<br />
						<h5 style="text-align: center;">
							Perubahan berhasil tersimpan
						</h5>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary text-light font-weight-bold py-3 mx-2 w-100" data-dismiss="modal">Kembali</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, charsRemover } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "vue-select/dist/vue-select.css";
import GoogleMap from '@/components/GoogleMap';

export default {
	name: "Warehouse",
	props: {
		show: Boolean,
		locationInputMode: Boolean,
		value: Object
	},
	data() {
		return {
			errors: [],
			method: "POST",
			selectedId: 0,
			defaultId: 1,
			addrOptions: [],
			addrJne: [],
			courierBranchOption: [],
			locationInputStep: 0,
			formTitle: "Tambah Alamat Pickup",
			formSender: {
				//to create pickup address
				id: '',
				addr_id: '',
				addr_detail: '',
				pic_name: '',
				sender_name: '',
				pic_tel: '',
				kodepos: '',
				code: '',
				name: '',
				addr_jne: '',
				branch: '',
				sender_latitude: '',
				sender_longitude: ''
			},
			listWarehouse: [],
			locationQuery: '',
			placeList: [],
			locationSearchTimeout: null,
			loading: false,
			loadingLocation: false,
			formattedAddress: ''
		};
	},
	components: {
		vSelect,
		maskedInput,
		CurrencyInput,
		GoogleMap
	},
	created() {
		//this.loadAddress();
		//this.loadCourierBranch();
		//this.loadAddrJne();
		//("warehouse", this.value);
	},
	methods: {
		loadCourierBranch() {
			const self = this;
			let route;
			route = `/order/courier_branch/3`;
			authFetch(route).then((res) => {
				res.json().then((json) => {
					if (json.success) {
						self.courierBranchOption = json.data;
						// self.courierBranchOption = json.data;
					} else {
						self.courierBranchOption = [];
					}
				});
			});
		},
		loadAddrJne() {
			const self = this;
			let route;
			route = `/order/addr_jne/3`;
			authFetch(route).then((res) => {
				res.json().then((json) => {
					if (json.success) {
						self.addrJne = json.data;
					} else {
						self.addrJne = [];
					}
				});
			});
		},
		loadAddress() {
			this.loading = true;
			authFetch("/order/address").then((res) => {
				res.json().then((json) => {
					//get default address
					if (json.success) {
						this.listWarehouse = json.data;
						this.addrOptions = this.listWarehouse;
						/*this.addrOptions.push({
							id: json.data.addr_id,
							lokasi: json.data.kota + " / " + json.data.kecamatan,
						});*/
					}
					this.loading = false;
				});
			});
		},
		findSeller() {
			var search = this.formSender.find_seller;
			if (search.length > 3 || search.length == 0) {
				this.loading = true;
				authFetch(
					`/order/address?keyword=${escape(search)}`
				).then(res => {
					res.json().then(json => (this.listWarehouse = json.data));
					this.loading = false;
				});
			}
		},
		onSelect(id) {
			const e = this.$refs;
			var newArray = this.listWarehouse.filter(function (el) {
				return el.shipper_address_id == id;
			});
			var sender = newArray[0];
			this.$emit("input", sender);
			this.$emit("change");
			$(e.formSelectPickup).modal("hide");
		},
		setSelected(value) {
			this.formSender.kodepos = value;
			var selectedAddress = this.addrOptions.filter((el) => {
				return el.id == value;
			});
			if(selectedAddress.length>0)
			{
				this.formSender.kecamatan = selectedAddress[0].kecamatan;
				this.formSender.kota = selectedAddress[0].kota;
				this.formSender.kodepos = selectedAddress[0].kodepos;
				this.formSender.addr_jne = selectedAddress[0].kode_jne;
				this.formSender.branch = selectedAddress[0].kode_cabang;
			}
		},
		onSearch(search, loading) {
			if (search.length > 3) {
				loading(true);
				var vm = this;
				authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
					res.json().then((json) => (vm.addrOptions = json.items));
					loading(false);
				});
			}
		},
		showDialog(e) {
			$(this.refs.formSelectPickup).modal(e ? "show" : "hide");
			$(this.refs.formAddPickup).modal(e ? "show" : "hide");
		},
		addPickup: function () {
			const e = this.$refs;
			this.formTitle = "Tambah Alamat Pickup";
			this.formSender = {};
			$(e.formAddPickup).modal("show");
			$(e.formSelectPickup).modal("hide");
		},
		mapInitialized() {
			this.$refs.googleMap.findPlaces(this.value.lokasi).then(places => {
				if (places.length > 0) {
					this.$refs.googleMap.selectLocation(places[0].location);
				}
			});
		},
		showLocationInputForm() {
			const e = this.$refs;

			this.formTitle = 'Tambah Pin Lokasi Agen';
			this.formSender = {
				id: this.value.shipper_address_id,
				addr_id: this.value.addr_id,
				addr_detail: this.value.addr_detail,
				addr_default: this.value.addr_default,
				pic_name: this.value.pic_name,
				sender_name: this.value.sender_name,
				pic_tel: this.value.pic_tel,
				kodepos: this.value.kodepos,
				code: this.value.code,
				name: '',
				addr_jne: this.value.addr_jne,
				branch: this.value.branch_code,
				branch_code: this.value.branch_code
			};

			this.addrOptions = [];

			const selectedList = this.listWarehouse.filter((el) => el.id == this.value.addr_id);

			if (selectedList.length > 0) {
				this.addrOptions.push(selectedList[0]);
			}

			this.locationInputStep = 1;
			$(e.formAddPickup).modal("show");
			$(e.formSelectPickup).modal("hide");
		},
		comingSoon: function() {
			Swal.fire({
				title: 'Coming Soon'
			});
		},
		editPickup: function (id) {
			$(this.$refs.formSelectPickup).modal("hide");
			let route = this.$router.resolve({
				path: "/seller"
			});
			window.open(route.href, "_blank");
			// const e = this.$refs;
			// authFetch("/order/address/" + id).then((res) => {
			//	 res.json().then((json) => {
			//		 //get default address
			//		 if (json.success) {
			//			 this.formSender = json.data;
			//			 this.addrOptions.push({
			//				 id: json.data.addr_id,
			//				 lokasi: json.data.kota + " / " + json.data.kecamatan,
			//			 });
			//		 }
			//	 this.formTitle = "Ubah Alamat Pickup";
			//		 $(e.formAddPickup).modal("show");
			//		 $(e.formSelectPickup).modal("hide");
			//	 });
			// });
		},
		removePickup: function (id, ev) {
			const swalWithBootstrapButtons = Swal.mixin({
				customClass: {
					actions: 'd-flex justify-content-between flex-row-reverse',
					cancelButton: 'btn btn-lg btn-outline-primary w-49 font-weight-bold',
					confirmButton: 'btn btn-lg btn-primary w-49 font-weight-bold',
				},
				buttonsStyling: false
			});
			swalWithBootstrapButtons.fire({
				title: 'Yakin ingin menghapus alamat?',
				showCancelButton: true,
				cancelButtonText: 'Tidak',
				confirmButtonText: 'Ya',
			}).then((result) => {
				if (result.isConfirmed) {
					authFetch("/order/address", {
						method: 'DELETE',
						body: 'id=' + id,
					}).
					then((res) => {
						res.json().then((json) => {
							if (json.success) {
								Swal.fire({
									title: 'Berhasil menghapus alamat'
								});
								ev.target.parentElement.parentElement.remove();
							} else {
								Swal.fire({
									title: 'Gagal menghapus alamat'
								});
							}
						});
					});
				}
			});
		},
		onLocationSearchInput: function () {
			if (this.locationSearchTimeout != null) {
				clearTimeout(this.locationSearchTimeout);
			}

			if (this.locationQuery.length > 0) {
				this.locationSearchTimeout = setTimeout(() => {
					$(this.$refs.mapDropdown).dropdown('show');
					this.$refs.googleMap.findPlaces(this.locationQuery).then(places => {
						this.placeList = places;
					});
				}, 3000);
			} else {
				this.placeList = [];
			}
		},
		searchMap: function (e) {
			if (this.locationQuery.length > 0) {
				if (e instanceof KeyboardEvent) {
					$(this.$refs.mapDropdown).dropdown('show');
				}

				this.loadingLocation = true;
				this.$refs.googleMap.findPlaces(this.locationQuery).then(places => {
					this.placeList = places;

					this.loadingLocation = false;
					$(this.$refs.mapDropdown).focus();
				});
			} else {
				this.placeList = [];
			}
		},
		selectMap: function (event) {
			this.formSender.sender_latitude = event.lat();
			this.formSender.sender_longitude = event.lng();
		},
		selectLocation: function (place) {
			this.locationQuery = place.displayName;
			this.$refs.googleMap.selectLocation(place.location);
		},
		updateFormattedAddress(address) {
			this.formattedAddress = address;
		},
		saveAddress: function () {
			var data = Object.keys(this.formSender)
				.map(
					(key) =>
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(this.formSender[key])
				)
				.join("&");
			var urlSubmit = "/order/address/" + this.formSender.id;

			authFetch(urlSubmit, {
				method: 'PUT',
				body: data,
			}).then((res) => {
				return res.json();
			}).then((js) => {
				this.errors = [];
				if (!js.success) {
					console.log(js.details);

					for (var key in js.details) {
						if (js.details.hasOwnProperty(key)) {
							this.errors.push(js.details[key]);
						}
					}

					return;
				}
				this.formSender.id = js.id;
				this.formSender.senderName = this.formSender.sender_name;
				this.formSender.senderTel = this.formSender.pic_tel;
				this.$emit("input", this.formSender);

				if (this.locationInputMode) {
					this.locationInputStep++;
				}
			});
			ev.preventDefault();
		},
		submitAddPickup: function (ev) {
			console.log("ev", ev);
			const e = this.$refs;
			var data = Object.keys(this.formSender)
				.map(
					(key) =>
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(this.formSender[key])
				)
				.join("&");
			var setmethod = this.formSender.id ? "PUT" : "POST";
			var urlSubmit = "/order/address";
			if (this.method == "PUT")
				urlSubmit = "/order/address/" + this.formSender.id;

			authFetch(urlSubmit, {
				method: setmethod,
				body: data,
			})
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					if (!js.success) {
						console.log(js.details);

						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}

						return;
					}
					// this.table.api().ajax.reload();
					// this.formSender = {};
					this.formSender.id = js.id;
					this.formSender.senderName = this.formSender.sender_name;
					this.formSender.senderTel = this.formSender.pic_tel;
					this.$emit("input", this.formSender);
					this.$emit("change");
					this.loadAddress();
					$(e.formSelectPickup).modal("hide");
					$(e.formAddPickup).modal("hide");

					if (this.locationInputMode) {
						this.locationInputStep++;
					}
				});
			ev.preventDefault();
		},
		setDefault: function (id) {
			const e = this.$refs;

			var urlSubmit = "/order/setdefault/" + id;
			authFetch(urlSubmit, {
				method: "PUT",
			})
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					if (!js.success) {
						console.log(js.details);

						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}
						return;
					}
					this.loadAddress();
				});
		},
		closeForm: function()
		{
			if (this.locationInputMode) {
				this.locationInputStep = 0;
			}

			$(this.$refs.formAddPickup).modal("hide");
			$(this.$refs.formSelectPickup).modal("show");
		}
	},
	mounted() {
		// console.log("warehouse1", this.value);
		const e = this.$refs;
		// if (this.show === true) $(e.formSelectPickup).modal("show");
		// console.log(this.show);
		this.locationInputStep = 0;

		$(e.formSelectPickup).on("show.bs.modal", (e) => {
			if(this.listWarehouse.length==0)
			{
				this.loadAddress();
			}
		});
	},
	watch: {
		show: function (old, val) {
			this.loadAddress();
			$(this.$refs.formSelectPickup).modal("show");
			//selected id
			this.selectedId = this.value.shipper_address_id;
			// console.log("id selected : ", this.selectedId);

			//addr_default id
			this.defaultId = this.value.addr_default;
			// console.log("addr_default : ", this.defaultId);
		},
		value: function (old, val) {},
		formSender: {
			handler(val) {
				this.formSender.addr_detail = charsRemover(val.addr_detail);
			},
			deep: true
		},
	},
};
</script>
<style scoped>
</style>