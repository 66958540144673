<template>
  <section class="content">
    <div class="card card-info">
        <div class="card-body">
            <div class="row" ref="toolbar">
                <div class="col-9">
                    <div class="row form-horizontal">
                        <div class="col-auto">
                            <button type="button" data-action="update" class="btn btn-info mr-2" :disabled="isDisabled"><i class="fas fa-edit"></i> <span class="d-none d-md-inline-block">Edit</span></button>
                            <button type="button" data-action="delete" class="btn btn-danger" :disabled="isDisabled"><i class="fas fa-trash"></i> <span class="d-none d-md-inline-block">Delete</span></button>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-end">
                    <div class="btn-group float-right">
                    <button type="button" data-action="read" class="btn btn-default"><i
                            class="fa fa-sync-alt"></i></button>
                    <button type="button" data-action="create" class="btn btn-primary"><i class="fa fa-plus"></i> <span class="d-none d-md-inline-block">Tambah</span></button>
                    </div>
                </div>
            </div>
            <div class="p-2"></div>
            <table class="table table-hover" ref="tableusercs">
            <thead>
                <tr>
                <th>Nama</th>
                <th>Email</th>
                <th>Code</th>
                </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            </table>
        </div>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
              </p>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Nama</label>
                  <input type="hidden" name="row_id" value="" />
                  <input
                    id="name"
                    class="form-control"
                    v-model="form.nama"
                    type="text"
                    required="required"
                    name="name"
                    minlength="4"
                    pattern="([A-z\s]){2,}"
                    title="Tidak boleh ada angka & simbol"
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Email</label>
                  <input type="hidden" name="row_id" value="" />
                  <input
                    id="email"
                    class="form-control"
                    v-model="form.email"
                    type="email"
                    required="required"
                    name="email"
                    onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==50 && event.shiftKey==true)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==true)
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Password</label>
                  <input type="hidden" name="row_id" value="" />
                  <input
                    id="pass"
                    class="form-control"
                    v-model="form.pass"
                    type="password"
                    name="pass"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Code</label>
                  <input type="hidden" name="row_id" value="" />
                  <input
                    id="code"
                    class="form-control"
                    v-model="form.code"
                    type="text"
                    required="required"
                    name="name"
                    minlength="4"
                    pattern="([A-z\s]){2,}"
                    title="Tidak boleh ada angka & simbol"
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isSubmit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "AdminShipper",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Data List User CS",
      form: {
        nama: "",
        email: "",
        pass: "",
        code: "",
      },
      isDisabled: true,
      isSubmit: false,
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    // showPass() {
    //   var x = document.getElementById("pass");
    //   if (x.type === "password") {
    //     x.type = "text";
    //   } else {
    //     x.type = "password";
    //   }
    // },
    submitForm: function (ev) {
      const e = this.$refs;
      this.isSubmit = true;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/shipper/user";
      if (this.method == "PUT") urlSubmit = "/shipper/user/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }
            this.isSubmit = false;

            return;
          }
          this.isSubmit = false;
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tableusercs, {
      title: "",
      roles: this.$route.params.roles,
      toolbarEl: e.toolbar,
      ajax: "/shipper/user",
      columns: [{ data: "nama" }, { data: "email" }, { data: "code" }],
      frame: false,
      filterBy: [0, 1],
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      rowCallback: function (row, data) {
      },
      rowClick: function (row) {
          if (Object.keys(row.data).length > 0) {
            self.isDisabled = false;
          }
      },
      drawCallback: function() {
          self.isDisabled = true;
      },
      buttonClick: (evt) => {
        if (evt.role == "read") {
            self.isDisabled = true;
        } else if (evt.role == "create") {
          document.getElementById('code').readOnly = false;
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Admin Shipper";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          if (!evt.data.code) {
            document.getElementById('code').readOnly = false;
          } else {
            document.getElementById('code').readOnly = true;
          }
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Admin Shipper";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          Swal.fire({
            title: "Hapus Admin Shipper?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/shipper/user/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>