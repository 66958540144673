<template>
	<section class="content">
		<div class="card-back w-100">
			<div class="card card-primary card-mx-auto">
				<div class="card-body">
					<div class="row custom-tab">
						<div class="col-4 col-sm-auto pt-3 px-0 cursor-pointer" :class="(notifikasi == 'order' ? 'active text-dark' : 'text-grey')" @click="toggleNotif('order')">
							<h6 class="d-none d-sm-block mx-5 mb-0 px-5 pb-3 font-weight-bold">Order</h6>
							<h6 class="d-block d-sm-none text-center mb-0 pb-3 font-weight-bold">Order</h6>
						</div>
						<div class="col-4 col-sm-auto pt-3 px-0 cursor-pointer" :class="(notifikasi == 'transaksi' ? 'active text-dark' : 'text-grey')" @click="toggleNotif('transaksi')">
							<h6 class="d-none d-sm-block mx-5 mb-0 px-5 pb-3 font-weight-bold">Transaksi</h6>
							<h6 class="d-block d-sm-none text-center mb-0 pb-3 font-weight-bold">Transaksi</h6>
						</div>
						<div class="col-4 col-sm-auto pt-3 px-0 cursor-pointer" :class="(notifikasi == 'informasi' ? 'active text-dark' : 'text-grey')" @click="toggleNotif('informasi')">
							<h6 class="d-none d-sm-block mx-5 mb-0 px-5 pb-3 font-weight-bold">Informasi</h6>
							<h6 class="d-block d-sm-none text-center mb-0 pb-3 font-weight-bold">Informasi</h6>
						</div>
					</div>
					<div  v-if="loadingNotif" style="" class="show-loading">
						<h6 class="text-primary mb-0">Memuat...</h6></div>
					<div v-if="notifikasi == 'order'">
						<div style="display: flex; margin-top: 12px; overflow: auto; gap:4px">
							<div v-if="filter==0" class="pill-orange">
								Semua
							</div>
							<div v-else class="pill" @click="toggleFilter(0)">
								Semua
							</div>
							<div v-if="filter==2" class="pill-orange">
								Terkirim
							</div>
							<div v-else class="pill" @click="toggleFilter(2)">
								Terkirim
							</div>
							<div v-if="filter==3" class="pill-orange">
								Proses Retur
							</div>
							<div v-else class="pill" @click="toggleFilter(3)">
								Proses Retur
							</div>
							<div v-if="filter==4" class="pill-orange">
								Retur
							</div>
							<div v-else class="pill" @click="toggleFilter(4)">
								Retur
							</div>
							<div v-if="filter==5" class="pill-orange">
								Bermasalah
							</div>
							<div v-else class="pill" @click="toggleFilter(5)">
								Bermasalah
							</div>
						</div>
						<div class="row border-bottom py-4 px-3" v-if="!listKiriman.length">
							<div class="col-10 col-sm-6 col-lg-4 m-auto text-center">
								<img class="w-100" :src="empty">
								<h4 class="text-center font-weight-bold">Notifikasi Kosong</h4>
								<h6 class="text-center font-weight-normal">Belum ada notifikasi terbaru saat ini</h6>
							</div>
						</div>
						<!--
						<div class="row border-bottom py-4 px-3" v-for="index in 5">
							<div class="col-auto pt-2">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm">
								<div class="d-flex justify-content-between">
									<h6 class="font-weight-bold">Paket bermasalah</h6>
									<small class="text-grey">14 September</small>
								</div>
								<h6 class="mb-0">Paket dengan nomor resi IDE683002022 sedang bermasalah.</h6>
							</div>
						</div>
						 -->
						<a :href="item.link" target="_blank" class="row border-bottom py-4 px-3 text-dark" v-for="item in listKiriman">
							<div style="display: flex; gap: 8px;">
								<div class="col-auto circle" center>
									<!-- <i class="fi fi-rr-bell h4"></i> -->
									<img src="@/assets/img/truck-check.svg" style="width: 20px; height: auto;" alt="truck">
								</div>
								<div style="gap:12px; display: flex; flex-direction: column;">
									<div class="d-flex" style="gap:12px">
										<h6 style="margin: 0px;" class="font-weight-bold">{{ item.title }}</h6> 
									<div style="display: flex; align-items: center;">
										<div class="dot"></div>
									</div>
										<small class="text-neutral-dark ">{{ formatTanggal(item.created_at) }}</small>
									</div>
									<div>
										<h6 class="mb-0 text-neutral-dark ">{{ item.body }}</h6>
									</div>
								</div>
							</div>
						</a>
					</div>
					<div class="pt-3 text-center" v-if="listKiriman.length && notifikasi == 'order'">
						<h6 v-if="loadingKiriman" class="text-primary mb-0">Memuat...</h6>
						<button v-else-if="!finishKiriman" class="btn btn-outline-warning" @click="loadKiriman">Muat lebih banyak</button>
					</div>
					<div v-if="notifikasi == 'transaksi'">
						<div class="row border-bottom py-4 px-3" v-if="!listTransaksi.length">
							<div class="col-10 col-sm-6 col-lg-4 m-auto text-center">
								<img class="w-100" :src="empty">
								<h4 class="text-center font-weight-bold">Notifikasi Kosong</h4>
								<h6 class="text-center font-weight-normal">Belum ada notifikasi terbaru saat ini</h6>
							</div>
						</div>
						<!--
						<div class="row border-bottom py-4 px-3" v-for="index in 5">
							<div class="col-auto pt-2">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm">
								<div class="d-flex justify-content-between">
									<h6 class="font-weight-bold">Paket bermasalah</h6>
									<small class="text-grey">14 September</small>
								</div>
								<h6 class="mb-0">Paket dengan nomor resi IDE683002022 sedang bermasalah.</h6>
							</div>
						</div>
						 -->
						<a :href="item.link" target="_blank" class="row border-bottom py-4 px-3 text-dark" v-for="item in listTransaksi">
							<div style="display: flex; gap: 8px;">
								<div class="col-auto circle-blue">
									<!-- <i class="fi fi-rr-bell h4"></i> -->
									<img src="@/assets/img/Topup-2.svg" style="width: 20px; height: auto;" alt="truck">
								</div>
								<div style="gap:12px; display: flex; flex-direction: column;">
									<div class="d-flex" style="gap:12px">
										<h6 style="margin: 0px;" class="font-weight-bold">{{ item.title }}</h6> 
									<div style="display: flex; align-items: center;">
										<div class="dot"></div>
									</div>
										<small class="text-neutral-dark ">{{ formatTanggal(item.created_at) }}</small>
									</div>
									<div>
										<h6 class="mb-0 text-neutral-dark ">{{ item.body }}</h6>
									</div>
								</div>
							</div>
						</a>
					</div>
					<div class="pt-3 text-center" v-if="listTransaksi.length && notifikasi == 'transaksi'">
						<h6 v-if="loadingKiriman" class="text-primary mb-0">Memuat...</h6>
						<button v-else-if="!finishKiriman" class="btn btn-outline-warning" @click="loadTransaksi">Muat lebih banyak</button>
					</div>
					<div v-if="notifikasi == 'informasi'">
						<div class="row border-bottom py-4 px-3" v-if="!listInformasi.length">
							<div class="col-10 col-sm-6 col-lg-4 m-auto text-center">
								<img class="w-100" :src="empty">
								<h4 class="text-center font-weight-bold">Notifikasi Kosong</h4>
								<h6 class="text-center font-weight-normal">Belum ada notifikasi terbaru saat ini</h6>
							</div>
						</div>
 						<!--
						<div class="row border-bottom py-4 px-3">
							<div class="col-auto mt-4 pt-2">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm">
								<small class="text-grey">14 September</small>
								<h6 class="font-weight-bold mt-2">Pengumuman Pemenang Giveaway BosCOD Spesial 10K Shippers!</h6>
								<h6>IDExpress sudah normal kembalo. Resi lama sudah berhasil ditracking. Jika ada kendala tracking, bisa diinfokan ke tim kami via livechat</h6>
							</div>
							<div class="col-lg-3 col-md-4 ml-auto d-flex align-items-center">
								<img src="https://i.ibb.co/kcR55V4/Notifikasi-Pict.png" alt="Promotion Image" class="w-100">
							</div>
						</div>
						 -->
						<a :href="item.link" target="_blank" class="row border-bottom py-4 px-3 text-dark" v-for="item in listInformasi">
							<div class="col-auto pt-2" v-if="!item.link">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm" v-if="!item.link">
								<div class="d-flex justify-content-between">
									<h6 class="font-weight-bold">{{ item.title }}</h6>
									<small class="text-grey">{{ formatTanggal(item.created_at) }}</small>
								</div>
								<h6 class="mb-0">{{ item.body }}</h6>
							</div>
							<div class="col-auto mt-4 pt-2" v-if="item.link">
								<i class="fi fi-rr-bell h4"></i>
							</div>
							<div class="col-sm" v-if="item.link">
								<small class="text-grey">{{ formatTanggal(item.created_at) }}</small>
								<h6 class="font-weight-bold mt-2">{{ item.title }}</h6>
								<h6>{{ item.body }}</h6>
							</div>
							<div class="col-lg-3 col-md-4 ml-auto d-flex align-items-center" v-if="item.link">
								<img :src="item.link" alt="Promotion Image" class="w-100">
							</div>
						</a>
					</div>
					<div class="pt-3 text-center" v-if="listInformasi.length && notifikasi == 'informasi'">
						<h6 v-if="loadingInformasi" class="text-primary mb-0">Memuat...</h6>
						<button v-else-if="!finishInformasi" class="btn btn-outline-warning" @click="loadInformasi">Muat lebih banyak</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import moment from "moment";

export default {
data() {
	return {
		notifikasi: 'order',
		filter: 0,
		empty: '',
		listKiriman: [],
		loadingKiriman: false,
		startKiriman: 0,
		finishKiriman: false,
		listInformasi: [],
		loadingInformasi: false,
		startTransaksi: 0,
		listTransaksi: [],
		startInformasi: 0,
		finishInformasi: false,
		loadingNotif: false,
	};
},
created: function () {
	this.empty = require('../assets/img/Empty-state.svg');
	this.loadKiriman();
	// this.loadInformasi();
},
methods: {
	toggleNotif(tipe) {
		this.notifikasi = tipe;
		if(tipe == 'order'){
			this.loadKiriman();
		}else if(tipe == 'transaksi'){
			this.loadTransaksi();
		}else if(tipe == 'informasi'){
			this.loadInformasi();
		}
	},
	toggleFilter(tipe) {
		this.filter = tipe;
		this.startKiriman = 0;
		this.listKiriman = [];
		this.finishKiriman = false;
		this.loadKiriman();
	},
	formatTanggal(date) {
		// return moment(date).lang("id").format('DD MMMM');
		const dt1 = parseInt(moment(date).format('X'));
        const dt2 = parseInt(moment().format('X'))-604800;
        const dt3 = parseInt(moment().format('X'))-120;

        var result = '';
        if(dt1 >= dt2){
            if(dt1 >= dt3){
                result = "Baru saja"
            }else{
                result = moment(date).lang("id").fromNow( );
            }
        }else{
            result = moment(date).lang("id").format('DD-MMM-YYYY');
        }
        return result;
	},
	loadKiriman() {
		this.loadingKiriman = true;
		this.loadingNotif = true;
		authFetch(`/report/notification?message_type=order` + (this.startKiriman > 0 ? `&start=` + this.startKiriman : ``)+(this.filter > 0 ? `&tracking_status=` + this.filter : ``))
		.then((res) => {
			res.json()
			.then((json) => {
				if (json.success) {
					if (this.start == 0) {
						this.startKiriman = this.startKiriman + 10;
						this.listKiriman = json.data;
					} else if (json.data.length == 0) {
						this.finishKiriman = true;
					} else {
						this.startKiriman = this.startKiriman + 10;
						this.listKiriman = this.listKiriman.concat(json.data);
					}

					this.loadingKiriman = false;
					// this.loadingNotif = false;
				} else {
					// this.listKiriman = [];
				}
			}).finish(
				this.loadingNotif = false
			);
		});
	},
	loadTransaksi() {
		this.loadingNotif = true;
		authFetch(`/report/notification?message_type=bospay` + (this.startTransaksi > 0 ? `&start=` + this.startTransaksi : ``)).then((res) => {
			res.json().then((json) => {
				if (json.success) {
					if (this.start == 0) {
						this.listTransaksi = json.data;
						this.startTransaksi = this.startTransaksi + 10;
					} else if (json.data.length == 0) {
						this.finishKiriman = true;
					} else {
						this.listTransaksi = this.listTransaksi.concat(json.data);
						this.startTransaksi = this.startTransaksi + 10;
					}
				} else {
					// this.listKiriman = [];
				}
			}).finish(
				this.loadingNotif = false
			);
		});
		this.loadingNotif = false;
	},
	loadInformasi() {
		this.loadingInformasi = true;
		this.loadingNotif = true;
		authFetch(`/report/notification?message_type=public&status=1` + (this.startInformasi > 0 ? `&start=` + this.startInformasi : ``)).then((res) => {
			res.json().then((json) => {
				if (json.success) {
					if (this.start == 0) {
						this.listInformasi = json.data;
						this.startInformasi = this.startInformasi + 10;
					} else if (json.data.length == 0) {
						this.finishInformasi = true;
					} else {
						this.listInformasi = this.listInformasi.concat(json.data);
						this.startInformasi = this.startInformasi + 10;
					}

					this.loadingInformasi = false;
					this.loadingNotif = false;
				} else {
					// this.listKiriman = [];
				}
			}).finish(
				this.loadingNotif = false
			);
		});
		this.loadingNotif = false;
	},
},
components: {},
mounted() {},
};
</script>
<style type="text/css">
.custom-tab div:nth-child(odd):after {
	margin-left: auto;
}
.custom-tab div:nth-child(even):after {
	margin-right: auto;
}
.custom-tab div:not(.active):after {
	content: '';
	display: block;
	height: 2.5px;
	width: 0px;
	background: #FF7203;
	transition: all .15s linear;
}
.custom-tab div.active:after {
	content: '';
	display: block;
	height: 2.5px;
	width: 100%;
	background: #FF7203;
	transition: all .15s linear;
}

.pill {
	display: flex; 
	padding: 10px 20px; align-items: center; 
	gap: 10px; 
	border-radius: 8px; 
	border: 1px solid var(--Neutral-Light-04, #B9BBC0); 
	background: #FFF; 
	height: fit-content;
    white-space: nowrap;
	/* margin: 12px; */
}
.pill-orange {
	display: flex; 
	padding: 10px 20px; align-items: center; 
	gap: 10px; 
	border-radius: 8px; 
	/* border: 1px solid var(--Neutral-Light-04, #B9BBC0);  */
	background: #FF7203; 
	color:#FFF;
	height: fit-content;
    white-space: nowrap;
	/* margin: 12px; */
}
.pill:hover {
  background: #ff6f00ad;
  color: #FFF;
}
.circle {
	display: flex;
	padding: 3px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 100px;
	background: var(--Main-Color-Primary-03, #FFDCC0);
	height: 25px;
	width: 25px;
}
.circle-blue {
	display: flex;
	padding: 3px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 100px;
	background: var(--Main-Color-Primary-03, #D1E4F5);
	height: 25px;
	width: 25px;
}
.dot {
  height: 6px;
  width: 6px;
  background-color: #D9D9D9;
  border-radius: 50%;
  display: inline-block;
}
.text-grey {
	color: #ACADAF;
}
.text-neutral-dark {
	color: #62676F;
}
.show-loading {
	animation: showItem 0.8s forwards;
	display: flex;
	justify-content: center;
	padding: 4px;
	margin: 0px 20%;
	background: rgb(249 249 249 / 62%);
	border-radius: 0px 0px 12px 12px;
}
</style>