<template>
	<section class="content">
		<div class="overlay-wrapper">
			<div class="card card-primary">
				<div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
				<div class="card-body">
					<div class="table-responsivex">
						<div class="row">
							<div class="col-6">
								<div v-if="selectedCount == 1" style="padding-right: 10px; justify-content: start;" ref="btnSelected" class="form-group">
									<button type="button" class="btn btn-primary" @click="editForm" style="flex: 1; display: flex;">
										<i class="fi fi-rr-edit mr-2" style="padding-top: 2px;"></i> Edit Pesanan
										<!-- <span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> -->
									</button>
								</div>
							</div> 

							<div class="row col-6" style="justify-content: end;">
								<div class="form-group col-auto col-sm-auto" style="padding-right: 10px;">
									<button type="button" class="btn font-weight-bold" @click="deleteForm" style="flex: 1; display: flex; border: 2px solid hsla(0, 87%, 45%, 1); color: hsla(355, 64%, 34%, 1);">
										<i class="fi fi-rr-trash mr-2" style="padding-top: 2px;"></i> Hapus Pesanan ({{selectedCount}})
										<!-- <span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> -->
									</button>
								</div>
								<div class="form-group col-auto col-sm-auto">
									<button type="button" class="btn btn-primary" style="flex: 1; display: flex;" @click="submitPesanan">
										<i class="fi fi-rr-add mr-2" style="padding-top: 2px;"></i> Proses Pesanan ({{selectedCount}})
										<!-- <span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> -->
									</button>
								</div>
							</div>
						</div>
						<div class="table-responsivex">
							<table class="table table-hover" ref="tableuser" id="tableuser" width="100%">
								<thead>
									<tr>
										<th>
											<input type="checkbox" ref="checkall" name="checkall" value="1" @click="selectAll" />
										</th>
										<th>Order ID</th>
										<th>Penerima</th>
										<th>Alamat Penerima</th>
										<th>Telepon</th>
										<th>Kurir</th>
										<th>Ongkir</th>
										<th>Nilai COD / Paket</th>
										<th>Nilai yang diterima</th>
										<th>Tipe</th>
										<th>Catatan Kurir</th>
										<th>CS</th>
									</tr>
								</thead>
								<tbody @click="handleClick">
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="prosesPesanan" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="submitForm">
						<div class="modal-header">
							<h5 class="modal-title text-bold">Proses Pesanan</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<i class="fi fi-rr-cross-small"></i>
							</button>
						</div>
						<div class="modal-body">
							<div  class="form-group bg-withdraw p-3">
								<div class="row" style="row-gap: 1rem">
									<div class="col">
										<div>{{selectedCountNonCod}} Paket NON-COD</div>
										<div style="margin-top: 10px;">{{selectedCountCod}} Paket COD</div>
									</div>
									<div class="col" style="text-align: end;">
										<div>Rp {{formatPrice(totalNonCod)}}</div>
										<div style="margin-top: 10px;">Pembayaran COD</div>
									</div>
								</div>
							</div>

							<div v-if="totalNonCod < infoSaldo" class="form-control bos-alert-danger lh-1 d-flex align-items-center h-100 w-fit">
								<div class="row">
									<div class="col-auto d-flex align-items-center">
										<i class="fi fi-rr-info d-flex align-items-center"></i>
									</div>
									<div class="col d-flex align-items-center">
										<div class="text-md">
											Saldo tidak cukup untuk proses pesanan paket Non COD. Kamu bisa topup saldo terlebih dahulu untuk melanjutkan proses pesanan.
										</div>
									</div>
								</div>
							</div>

							<div v-if="info.role_id != 12" class="form-group p-3" style="border:2px #F0F0F0 solid; border-radius: 7px;">
								<div class="row" style="row-gap: 1rem">
									<div class="col-1" style="background-color: #F0F0F0; ;border-radius: 50px;">
										<i class="fi fi-rr-wallet" style="color: green; align-items: center; text-align: center; justify-content:center; font-size: 18px;"></i>
									</div>
									<div class="col">
										<div style="color: grey;">Saldo Bospay</div>
										<div style="margin-top: 5px; font-weight: bold;">Rp {{infoSaldo}}</div>
									</div>
									<router-link :to="{ path: '/saldo' }" class="col" style="text-align: end;" data-dismiss="modal">
										<button type="button" class="btn btn-primary">Top Up</button>
									</router-link>
								</div>
							</div>
							<div class="modal-footer row pt-3" style="justify-content: end;">
								<button type="reset" @click="form.courier_id = 0" class="btn btn-outline-primary font-weight-bold" data-dismiss="modal"  style="margin-right: 10px;">Proses Nanti</button>
								<button type="submit" class="btn btn-primary">Proses Sekarang</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { createTable, authFetch, auth, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Historyfilter from "@/dialog/Historyfilter";
import FormClaim from "@/dialog/FormClaim";
import FormClosingClaim from "@/dialog/FormClosingClaim";
import moment from "moment";
import "icheck-material";
export default {
	name: "Riwayat",
	data() {
		return {
			roles: "",
			show: false,
			selectedCount: 0,
			selectedCountCod: 0,
			selectedCountNonCod: 0,
			totalNonCod: 0,
			downloading: false,
			loadingContent: false,
			submitting: false,
			selectedItems: [],
			customSwal: {},
			infoSaldo: 0,
		};
	},
	components: {
		Historyfilter,
		FormClaim,
		FormClosingClaim
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		this.info = auth.user();
		
		if(this.info){
			if(this.info.role_id == 11){
				this.loadSaldo();
			}
		}
	},
	methods: {
		submitPesanan: function (e) {
			$(this.$refs.prosesPesanan).modal("show");
		},
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-order');

			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0){
				var nRow = $(e.target).closest("tr").get(0);
				var iRow = this.table.fnGetPosition(nRow);
				var data = this.table.fnGetData(iRow);
				var nilai_ongkir = 0;

				this.selectedCount = checkboxes.length;
				this.selectedItems = data;

				if (val == true){
					for (var j = 0; j < data.length; j++) {
						if (data[j].status_cod == 1){
							this.selectedCountCod = 1 + j;
						} else {
							this.selectedCountNonCod = 1 + j;
							nilai_ongkir = nilai_ongkir + parseInt(data[j].nilai_ongkir);
						}
					}
				}

				this.totalNonCod = nilai_ongkir;
			} else {
				this.selectedCount = 0;
				this.selectedCountCod = 0;
				this.selectedCountNonCod = 0;
				this.totalNonCod = 0;
			}
		},
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/order/create/draft/" + e.target.dataset.id
				});
				window.open(route.href,"_self");

			} else if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-order:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
					this.selectedCountCod = 0;
					this.selectedCountNonCod = 0;
					this.totalNonCod = 0;
				} else {
					this.$refs.checkall.checked = true;
					var selected = [];
					var nilai_ongkir = 0;
					var dataTable = this.table.api().data().toArray();

					var nRow = $(e.target).closest("tr").get(0);
					var iRow = this.table.fnGetPosition(nRow);
					var data = this.table.fnGetData(iRow);

					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].checked){
							let filteredData = dataTable.filter(value => value.order_code == checkboxes[i].value);
							selected.push(filteredData[0]);
							if (data.status_cod == 1){
								this.selectedCountCod = 1 + i;
							} else {
								this.selectedCountNonCod = 1 + i;
								nilai_ongkir = nilai_ongkir + parseInt(filteredData[0].nilai_ongkir);
							}
						} 
					}

					this.totalNonCod = nilai_ongkir;
					this.selectedItems = selected;
				}
				this.selectedCount = checkboxes.length;
			}
		},
		getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();
			return fileName? require(`../assets/img/${fileName}.png`) : '';
		},
		getImage(name) {
			if (!name) return;
			const fileName = name.toLowerCase();
			return require(`../assets/img/${fileName}.png`);
		},
		loadSaldo: function (code) {
			authFetch("/payment/saldo/current").then((res) => {
				res.json().then((json) => {
				if (json.success) {
					this.infoSaldo = formatCurrency(json.data);
				}
				});
			});
		},
		submitForm: function(e) {
			var submit_status = true;

			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.selectedItems[i].order_status == 0){
					submit_status = false;
					break;
				} else if (this.selectedItems[i].order_status == 1 && this.selectedItems[i].error_message != null){
					submit_status = false;
					break;
				} else {
					submit_status = true;
				}
			}

			$(this.$refs.prosesPesanan).modal("hide");
			this.customSwal.fire({
				// title: 'Yakin ingin proses pesanan sekarang?',
				html: '<img src="' + this.getImage('kartu') + '" class="d-block m-auto"/><br /><h4>Yakin ingin proses pesanan sekarang?</h4>',
				showDenyButton: true,
				confirmButtonText: 'Proses Sekarang',
				denyButtonText: 'Kembali',
			}).then((result) => {
				if (result.isConfirmed) {

					if (submit_status == false){
						this.customSwal.fire({
							title: "Gagal import pesanan",
							html: "Gagal generate",
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: 'Kembali',
						})
					} else {
						this.submitting = true;
						this.loadingContent = true;

						authFetch("/order/submit_darft", {
							// authFetch("/order/neworder/submitimport", {
								method: "POST",
								headers: {
									Accept: "application/json",
									"Content-Type": "application/json"
								},
								body: JSON.stringify(this.selectedItems)
						})
						.then((res) => {
							this.submitting1 = true;
							return res.json();
						})
						.then((js) => {
							this.loadingContent = false;
							if (js.success == true) {
								this.submitting1 = false;

								this.customSwal.fire({
									title: 'Pesanan Berhasil Dibuat',
									html: '<img src="' + this.getImage('ok') + '" class="d-block w-75 m-auto" />Pesanan kamu berhasil dibuat, silahkan cek label<br />kamu dan tempel di paket kamu.',
										showConfirmButton: false,
										showCancelButton: true,
										cancelButtonText: 'Lanjutkan',
								}).then(() => {
									this.$router.push("/order");
								});
							} else {
								this.submitting1 = false;
								let detailData = "";

								if (js.errors.length != 0) {
									var dataError = [];
									detailData ='<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';

									for (let i in js.errors) {
										detailData += "<b>Kode "+ js.errors[i].code +"</b><br><i>"+ js.errors[i].message +"</i><br><br>";
										var row = $("[value='" + js.errors[i].code + "']").closest("tr").get(0);
										$(row).css('background-color', '#F7EBEB');
										dataError.push(js.errors[i].code);

									}
									localStorage.setItem('data_order_code_error', dataError);
									detailData += "</div>";
									Swal.fire({
										title: js.message,
										icon: "error",
										html: detailData,
										showCloseButton: true,
									});
								} else {
									this.customSwal.fire({
										title: "Gagal import pesanan",
										html: js.message,
										showConfirmButton: false,
										showCancelButton: true,
										cancelButtonText: 'Kembali',
									})
								}
							}
						});
					}
				}
			});
		},
		formatPrice(data) {
			if (data == 0) {
				return 0;
			} else {
				let format = formatCurrency(data);
				return format.replaceAll(".", ",");
			}
		},
		deleteForm: function(ev) {
			this.customSwal.fire({
				html: '<img src="' + this.getImage('trash') + '" class="d-block m-auto"/><br /><h4>Yakin ingin hapus sekarang?</h4>',
				showDenyButton: true,
				confirmButtonText: 'Hapus',
				denyButtonText: 'Kembali',
			}).then((result) => {
				if (result.isConfirmed) {
					this.submitting = true;
					authFetch("/order/delete_darft", {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems),
					})
					.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
					})
					.then((js) => {
						this.submitting = false;
						this.show= false,
						this.selectedCount= 0,
						this.selectedCountCod= 0,
						this.selectedCountNonCod= 0,
						this.totalNonCod= 0,
						this.downloading= false,
						this.loadingContent= false,
						this.selectedItems= [],
						this.table.api().ajax.reload();
					})
				}
			})
		},
		editForm: function(ev) {
			let route = this.$router.resolve({
				path: "/order/create/draft/" + this.selectedItems[0].order_code
			});
			window.open(route.href,"_self");
		},
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Buat Kiriman Batch'
		});

		const e = this.$refs;
		const self = this;
		
		// var filterBy = [1, 2, 3, 4, 5, 6, 12, 10, 11];

		this.table = createTable(e.tableuser, {
			title: "Data Batch",
			autoWidth: false,
			responsive: true,
			scrollX: true,
			scrollY: 430,
			roles: this.$route.params.roles,
			ajax: "/order/riwayat_draft",
			frame: false,
			toolbarEl: e.toolbar,
			lengthChange: false,
			filter: false,
			stateSave: true,
			processing: true,
			paging: false,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			columns: [
				{
					"data": "checkall", 
					"defaultContent": '<input type="checkbox">',
					sortable: false,
					render: function (data, type, row, meta) {
						return (
							'<input type="checkbox" class="select-order" value="'+row.order_code+'" />'
						);
					},
				},
				{
					data: "order_code",
					sortable: false,
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="${data}">${data}</span>`);
					},
				},
				{
					data: "receiver_name",
					sortable: false,
				},
				{
					data: "receiver_address_str",
					width: "25%",
					sortable: false,
				},
				{
					data: "receiver_phone",
					sortable: false,
				},
				{
					data: "prefix",
					sortable: false,
					render: function (data, type, row, meta) {
						if (!data) return '';
						var logo = data.toLowerCase();
						return '<img class="d-block m-auto" height="25" src="' + self.getKurirLogo(logo) + '" /><small class="d-block text-center">' + (row.service_code ? row.service_code : '') + '</small>';
					},
				},
				{
					data: "nilai_ongkir",
					sortable: false,
					render: function (data, type, row, meta) {
						if (row.order_status == 0){
							return '<span class="d-block text-center">Generate Ongkir</span>';
						} else if (row.order_status == 1 && row.error_message != null){
							return '<span class="d-block text-center">Gagal Generate Ongkir</span>';
						} else {
							if (data == 0) {
								return '<span class="d-block text-center""></span>';
							} else {
								let format = formatCurrency(data);
								return '<span>Rp'+format.replaceAll(",", ".")+'</span>';
							}
						}
					},
				},
				{
					data: "nilai_cod",
					sortable: false,
					render: function (data, type, row, meta) {
						if (parseInt(row.status_cod) == 0){
							if (parseFloat(row.item_value) == 0) {
								return '<span class="d-block text-center">0</span>';
							} else {
								let format = formatCurrency(row.item_value);
								return '<span class="d-block text-center">Rp '+format.replaceAll(",", ".")+'</span>';
							}
						} else {
							if (data == 0) {
								return '<span class="d-block text-center">0</span>';
							} else {
								let format = formatCurrency(data);
								return '<span class="d-block text-center">Rp '+format.replaceAll(",", ".")+'</span>';
							}
						}
					},
				},
				{
					data: "grand_total",
					sortable: false,
					align: 'center',
					render: function (data, type, row, meta) {
						if (!data) {
							return '<span class="d-block text-center">-</span>';
						} else {
							let format = formatCurrency(data);
							return '<span class="d-block text-center">Rp'+format.replaceAll(",", ".")+'</span>';
						}
					},
				},
				{
					data: "status_cod",
					sortable: false,
					render: function (data, type, row, meta) {
						var badge = '';
						if (data == 1) {
							badge = ' <span class="badge badge-warning text-center">COD</span>';
						} else {
							badge = ' <span class="badge badge-warning text-center">Non-COD</span>';
						}
						return badge;
					},
				},
				{
					data: "note",
					sortable: false,
				},
				{
					data: "ext_field1",
					sortable: false,
					render: function (data, type, row, meta) {
						var res = '';
						if (data) {
							res = ' <span>'+data+'</span>';
						} else {
							res = ' <span>-</span>';
						}
						return res;
					},
				},
			],

			drawCallback: function (row, data) {
				self.$refs.checkall.checked = false;
			},

			initComplete: function () { 
				$('.loading-overlay').removeClass('show');

				let data_code_error = localStorage.getItem('data_order_code_error');
				if (data_code_error){
					let data_error = data_code_error.split(",");
					for (let i in data_error) {
						let row = $("[value='" + data_error[i] + "']").closest("tr").get(0);
						$(row).css('background-color', '#F7EBEB');
					}
				}
			},
		});

		this.customSwal = Swal.mixin({
			customClass: {
			popup: 'w-fit',
			title: 'h4 tex-dark',
			actions: 'd-flex justify-content-between flex-row-reverse',
			confirmButton: 'btn btn-primary w-49 font-weight-bold py-3',
			denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-3',
			cancelButton: 'btn btn-primary btn-block font-weight-bold py-3',
			},
			buttonsStyling: false
		});
	},
};
</script>

<style scoped>
	.dataTables_length{padding-top: 0.5em;}

	.table th {
		padding: 0.3rem;
	}
	
	.table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:after {
		content: "";
	}

	table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:before {
		content: "";
	}

	table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting_asc_disabled, table.dataTable thead > tr > th.sorting_desc_disabled, table.dataTable thead > tr > td.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting_asc_disabled, table.dataTable thead > tr > td.sorting_desc_disabled {
		padding-right: 5px;
		padding-left: 5px;
	}

	.bg-withdraw {
		background-color: 	#F0F0F0;
		background-repeat: no-repeat;
		background-size: 100%;
		margin-top: 15px;
	}

	.col-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
		align-items: center;
		text-align: center;
		justify-content: center;
		display: flex;
	}

	.modal-footer {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: end;
		justify-content: flex-end;
		padding-bottom: 0rem;
		border-top: 1px solid #e9ecef;
		border-bottom-right-radius: 0rem;
		border-bottom-left-radius: 0rem;
	}
	
</style>