<template>
    <div id="map"></div>
</template>
<script>
    import { Loader } from "@googlemaps/js-api-loader";
    
    export default {
        name: "GoogleMap",
        data() {
            return {
                map: null,
                center: null,
                marker: null,
                formattedAddress: null
            }
        },
        emits: ['centerChanged', 'mapInitialized', 'formattedAddressChanged'],
        mounted() {
            this.initMap();
        },
        methods: {
            initMap: async function () {
                const additionalOptions = {};
                const loader = new Loader({
                    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
                    version: "weekly",
                    ...additionalOptions,
                });

                const { Map } = await loader.importLibrary("maps");
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

                this.center = { lat: -6.2088, lng: 106.8456 };
                this.map = new Map(document.getElementById("map"), {
                    center: this.center,
                    zoom: 15,
                    mapId: "shipper-map",
                    streetViewControl: false,
                    mapTypeControl: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    fullscreenControl: false
                });
                this.marker = new AdvancedMarkerElement({
                    map: this.map,
                    position: this.map.getCenter(),
                    title: 'Lokasi yang Dipilih',
                });
                this.map.addListener("center_changed", () => {
                    this.marker.position = this.map.getCenter();                    

                    this.$emit('centerChanged', this.map.getCenter());
                });

                this.map.addListener("dragend", () => {
                    const center = this.map.getCenter();
                    this.loadFormattedAddress(center);
                });

                this.loadFormattedAddress(this.center);

                this.$emit('mapInitialized', this.map);
            },
            findPlaces: async function (query) {
                const { Place } = await google.maps.importLibrary("places");
                const request = {
                    textQuery: query ?? 'Indonesia',
                    fields: ['displayName', 'formattedAddress', 'location'],
                    maxResultCount: 3,
                    language: 'id-ID',
                    region: 'id',
                    useStrictTypeFiltering: false,
                };
                //@ts-ignore
                const { places } = await Place.searchByText(request);

                console.log(places);

                return places;
            },
            selectLocation: function(location) {
                this.map.setCenter(location);
                this.marker.position = location;

                this.loadFormattedAddress(location);
            },
            loadFormattedAddress: async function(location) {
                const geocoder = new google.maps.Geocoder();
                const request = {
                    location: location
                };
                //@ts-ignore
                const { results } = await geocoder.geocode(request);

                if (results.length > 0) {
                    this.formattedAddress = results[0].formatted_address;
                    this.$emit('formattedAddressChanged', this.formattedAddress);
                }
            }
        }
    }
</script>
<style scoped>
    #map {
        height: 350px;
        width: 100%;
    }
</style>