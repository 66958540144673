<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form role="form" @submit.prevent="submitForm">
                        <div class="invoice p-3 mb-3">
                            <!-- title row -->
                            <!-- info row -->
                            <!-- /.row -->
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">LOKASI PENJEMPUTAN</label>
                                    <div class="border rounded p-2 position-relative">
                                        <div style="min-height:50px;">
                                            <div v-if="sender.id">
                                                <strong> {{ sender.senderName }} </strong><br />
                                                {{ sender.senderTel }} ({{ sender.pic_name }})<br />
                                                {{ sender.addr_detail }} <br />
                                                {{ sender.kecamatan }}, {{ sender.kota }},
                                                {{ sender.kodepos }}
                                            </div>
                                        </div>
                                        <div v-if="og_status != 1" class="position-absolute" style="right: 5px; top: 5px">
                                            <button type="button" class="btn btn-secondary btn-sm" v-on:click="selectWarehouse">
                                                <i class="fa fa-map-pin"></i> Pilih Alamat
                                            </button>
                                        </div>
                                        <input type="hidden" name="shipper_addr_id" id="shipper_addr_id" v-model="form.shipper_addr_id" />
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-3 form-group">
                                    <label class="control-label">KURIR</label>
                                    <select required v-model="form.courier_id" id="courier_id" name="courier_id" class="form-control" :disabled="shouldDisable" @change="kurirChange($event)">
                                        <option v-for="courier in totalCourier" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id" v-bind:title="courier.drop_off">
                                            {{ courier.nama_kurir }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-3 form-group">
                                    <label class="control-label">TIPE ORDER</label>
                                    <div class="rowx">
                                        <div class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      ">
                                            <input class="form-check-input" type="radio" name="order_type" id="order_type" v-model="form.order_type" @change="onChange($event)" value="0" :disabled="og_status == 1" />
                                            <label class="form-check-label" for="order_type" >Pickup</label>
                                        </div>
                                        <div class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      ">
                                            <input class="form-check-input" type="radio" name="order_type" id="order_type2" v-model="form.order_type" @change="onChange($event)" value="1" :disabled="droppOffDisabled" />
                                            <label class="form-check-label" for="order_type2">Drop Off</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 form-group" v-if="form.order_type == 0">
                                    <label id="labelpickup_via1" class="control-label">JEMPUT MELALUI</label>
                                    <div class="rowx">
                                        <div class="form-check form-check-inline icheck-material-teal icheck-inline">
                                            <input class="form-check-input" type="radio" required id="pickup_via1" name="pickup_via" v-model="form.pickup_via" value="1" :disabled="og_status == 1" />
                                            <label class="form-check-label" id="labelpickup_via2" for="pickup_via1">Motor</label>
                                        </div>
                                        <div class="form-check form-check-inline icheck-material-teal icheck-inline">
                                            <input class="form-check-input" type="radio" required id="pickup_via2" name="pickup_via" v-model="form.pickup_via" value="2" :disabled="og_status == 1" />
                                            <label class="form-check-label" id="labelpickup_via2" for="pickup_via2">Pickup</label>
                                        </div>
                                        <div class="form-check form-check-inline icheck-material-teal icheck-inline">
                                            <input class="form-check-input" type="radio" required id="pickup_via3" name="pickup_via" v-model="form.pickup_via" value="3" :disabled="og_status == 1" />
                                            <label class="form-check-label" id="labelpickup_via3" for="pickup_via3">Truk</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 form-group" v-if="form.order_type == 0">
                                    <label class="control-label">WAKTU PENJEMPUTAN</label>
                                    <input type="text" class="form-control" ref="daterange">
                                </div>
                            </div>
              <div class="row" v-if="form.order_type==0">
<span class="callout callout-warning"><strong>Catatan:</strong> Mohon jangan diantarkan ke cabang, kurir akan pickup ke tempat Anda.</span>
              
              </div>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">CATATAN</label>
                                    <input id="note" class="form-control" v-model="form.note" type="text" name="note" :disabled="og_status == 1" />
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <!-- form order items -->
                            <h4 class="mb-2">
                                <i class="fa fa-tags"></i> &nbsp; Data Pesanan
                            </h4>
                            <div v-if="og_status == 1" class="text-rightx"></div>
                            <div v-else class="text-rightx">
                                <button type="button" v-on:click="addItem" data-action="create" class="btn btn-info" :title="(!form.courier_id || !sender.id)?'Pilih Lokasi Penjemputan dan Kurir':'Buat Baru'" :disabled="!form.courier_id || !sender.id">
                                    <i class="fas fas fa-plus"></i> Tambah Item
                                </button>
                            </div>
                            <table class="table table-hover" id="tblorder" ref="tableorder">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>ORDER</th>
                                        <th>NO. ORDER</th>
                                        <th>NO. RESI</th>
                                        <th>PENERIMA</th>
                                        <th>TELEPON</th>
                                        <th>ALAMAT</th>
                                        <th>BERAT</th>
                                        <th>QTY</th>
                                        <th>NILAI</th>
                                        <th>COD</th>
                                        <th>CS</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody @click="handleClick"></tbody>
                            </table>
                        </div>
                        <!-- form select pickup address per shipper id -->
                        <Warehouse :show="show" v-model="sender" v-on:change="semuaOngkir" />
                        <Warehouse :show="showLocationInput" :locationInputMode="true" v-model="sender" />
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <div v-if="og_status == 1">
                                <div class="box-tools card-tools toolbar mr-2"></div>
                            </div>
                            <button v-if="og_status != 1" type="submit" @click="submitType='submit'" id="submit" class="btn btn-primary mr-2" :disabled="submitting1">
                                <i class="fas fa-check"></i> Submit Pesanan
                                <span v-if="submitting1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button v-if="og_status != 1" type="submit" @click="submitType='draft'" id="draft" class="btn btn-success mr-2" :disabled="submitting2">
                                <i class="fas fa-edit"></i> Simpan Draft
                                <span v-if="submitting2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <router-link to="/order" class="btn btn-secondary ml-2 float-right d-none d-sm-block">
                                <i class="fas fa-arrow-left"></i> Kembali
                            </router-link>
                        </div>
                        <!-- Tambah Manual -->
                    </form>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit.prevent="saveItem" v-on:keydown.enter.prevent="false">
                        <div class="modal-header">
                            <h4 class="modal-title">Form Order</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="bg-secondary p-3 mb-3 d-none">
                                <i class="fa fa-info"></i> INFORMASI PENERIMA
                            </div>
                            <div class="row">
                                <input type="hidden" name="id" id="id" v-model="formItem.id" />
                                <input type="hidden" name="orderNo" id="orderNo" v-model="formItem.orderNo" />
                                <input type="hidden" name="order_code" id="order_code" v-model="formItem.order_code" />
                                <input type="hidden" name="no_resi" id="no_resi" v-model="formItem.no_resi" />
                                <div class="col-md-6 form-group">
                                    <label class="control-label">NAMA PENERIMA</label>
                                    <input type="hidden" name="row_id" value="" />
                                    <input id="destName" class="form-control" v-model="formItem.destName" type="text" minlength="3" required="required" name="destName[]" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">TELEPON</label>
                                    <input id="destTel" pattern="[0-9]{7,}" formnovalidate class="form-control" v-model="formItem.destTel" type="number" required="required" name="destTel[]" minlength="10" maxlength="16" v-bind:class="{ 'is-invalid': destTelErr }" />
                                    <div class="invalid-feedback" id="feedback-1" v-if="errors[0]">
                                        <h6>
                                            {{ errors[0].message }}
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">KOTA / KECAMATAN</label>
                                    <v-select required label="name" name="addr_id[]" :filterable="false" :options="addrOptions" v-model="formItem.addr_id" :reduce="(opt) => opt.id" @search="onSearch" @input="setSelected">
                                        <template slot="no-options"> Ketik alamat... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.lokasi }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.lokasi }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">ALAMAT</label>
                                    <textarea class="form-control" v-model="formItem.addr_detail" required="required" name="addr_detail[]"></textarea>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">ISI PAKET</label>
                                    <input id="item_name" class="form-control" v-model="formItem.item_name" type="text" required="required" name="item_name[]" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">HARGA PRODUK</label>
                                    <currency-input class="form-control" currency="IDR" @keydown="" v-model="formItem.item_value" v-bind:class="{ 'is-invalid': false }" :precision="0" :valueRange="{ 'min': 1 }" name="item_value[]" placeholder="Rp" required="required" />
                                    <div style="margin-top: 5px; margin-left: 2px">
										<span class="text-xs text-primary" >Pastikan harga produk sesuai aslinya!</span>
									</div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label style="padding: 1px 0" class="control-label">BERAT (Kg)</label>
                                    <div class="input-group">
                                        <input @change="onChangeOngkir($event)" class="form-control mask" v-model="formItem.weight" type="number" min="0.1" step="0.1" required="required" placeholder="0,1 - 99" name="weight[]" 
                                            onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==190 && event.shiftKey==false) 
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="control-label">QTY</label>
                                    <button type="button" class="btn" v-tooltip="{ content: 'Isi barang dalam 1 koli / paket' }" style="padding: 0 4px"><i class=" fas fa-info-circle"></i></button>
                                    <input class="form-control mask" v-model="formItem.item_qty" type="number" placeholder="Cth: 1" name="item_qty[]" min="1"
                                    onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==190 && event.shiftKey==false) 
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                                </div>
                                <div class="col-md-4 form-group">
                                   
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="control-label">PANJANG (Cm)</label>
                                    <input @change="onChangeOngkirPlt()" class="form-control mask" v-model="formItem.length" type="number" placeholder="Cth: 10" name="length[]" min="0" 
                                    onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==190 && event.shiftKey==false) 
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="control-label">LEBAR (Cm)</label>
                                    <input @change="onChangeOngkirPlt()" class="form-control mask" v-model="formItem.width" type="number" placeholder="Cth: 10" name="width[]" min="0"
                                    onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==190 && event.shiftKey==false) 
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="control-label">TINGGI (Cm)</label>
                                    <input @change="onChangeOngkirPlt()" class="form-control mask" v-model="formItem.height" type="number" placeholder="Cth: 10" name="height[]" min="0"
                                    onkeydown="return (event.ctrlKey || event.altKey 
											|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==190 && event.shiftKey==false) 
											|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
											|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                                </div>
                                <div class="col-md-3 form-group mt-3">
                                    <label class="control-label">STATUS</label>
                                    <div @click="!!codDisabled && verifKtp()" >
                                        <toggle-button @change="onToggleChange($event)" :labels="{ checked: 'COD', unchecked: 'NON COD' }" :width="80" :sync="true" :value="Boolean(formItem.codStatus)" v-model="formItem.codStatus" :disabled="codDisabled"/>
                                    </div>
                                </div>
                                <div class="col-md-6 form-group mt-3">
                                    <label class="control-label">
                                        <!-- {{
                                        formItem.codStatus ? "Nilai COD" : "Nilai Paket"
                                        }} -->
                                        Nilai COD (OPSIONAL)
                                    </label>
                                    <currency-input class="form-control" currency="IDR" @change="hitungOngkir($event)" v-model="formItem.codValue" v-bind:class="{ 'is-invalid': codValueErr }" :precision="0" name="codValue[]" :disabled="formItem.codStatus ? false : true"/>
                                    <div class="invalid-feedback" max="2500000" id="feedback-1" v-if="errors[0]">
                                        <h6>
                                            {{ errors[0].message }}
                                        </h6>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 mt-3">
                                    <label class="control-label">STATUS ASURANSI</label>
                                    <div>
                                        <div class="icheck-material-orange icheck-inline form-check">
                                            <input class="form-check-input" :disabled="!enableInsuranceChange" id="insurance_type" v-model="formItem.insurance_type" type="checkbox" name="insurance_type[]" @change="hitungOngkir($event)" />
                                            <label class="form-check-label" for="insurance_type">
                                                Asuransi
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 form-group mb-3">
                                    <label class="control-label">CATATAN</label>
                                    <input id="note" class="form-control" v-model="formItem.note" type="text" name="note[]" />
                                </div>
                                <div class="col-md-6 form-group mb-3">
                                    <label class="control-label">CS</label>
                                    <select v-model="formItem.extfield1" class="form-control" :disabled="role_id == 12 ? true : false">
                                        <option v-for="value in AdminShipper" v-bind:value="value">{{ value }}</option>
                                    </select>
                                </div>
                                <div class="col-md-12 form-group">
                                    <div>
                                        Biaya Standar:
                                        <input type="hidden" name="nilai_ongkir[]" id="nilai_ongkir" v-model="formItem.nilai_ongkir" /><strong>
                                            {{
                                            formItem.ongkir_dasar
                                            ? formatMoney(formItem.ongkir_dasar+formItem.totalFeeCod+formItem.insurance_fee)
                                            : "-"
                                            }}</strong>
                                    </div>
                                    <div>
                                        Estimasi Biaya dengan BosCOD:
                                        <strong>
                                            {{
                                            formItem.nilai_ongkir
                                            ? formatMoney(formItem.nilai_ongkir + formItem.totalFeeCod +formItem.insurance_fee)
                                            : "-"
                                            }}</strong>
                                    </div>
                                    <div v-if="formItem.codStatus">
                                        Estimasi jumlah yang akan diterima :
                                        <strong>{{
                                            formItem.nilai_ongkir && formItem.codValue ? formatMoney(formItem.totalReceived) : formItem.item_value
                                        }}</strong>
                                    </div>
                                    <div v-if="formItem.codStatus">
                                        Ditagihkan ke customer
                                        <strong>{{
                                            formItem.nilai_ongkir && formItem.codValue ? formatMoney(formItem.codValue) : formatMoney((formItem.item_value + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.feeCod / 100) + ((formItem.ppnCod / 100) * (formItem.feeCod / 100)))))
                                        }}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" id="append" @click="submitItemType='append'" class="btn btn-primary" :disabled="loadingOnkgir || disableSubmit">
                                Tambah item <span v-if="loadingOnkgir" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button type="submit" id="update" @click="submitItemType='update'" class="btn btn-default" :disabled="loadingOnkgir || disableSubmit">
                                Update item <span v-if="loadingOnkgir" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button type="button" @click="tutupItem" class="btn btn-secondary">
                                Tutup
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vue from "vue";
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import Warehouse from "@/dialog/Warehouse";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "dropzone/dist/dropzone.css";
import "vue-select/dist/vue-select.css";

import Daterangepicker from 'daterangepicker';

import moment from "moment";
import "icheck-material";
import "daterangepicker/daterangepicker.css";

Vue.use(ToggleButton);

export default {
    data() {
        return {
            optPickupTime: {
                minDate: moment().add(4, "h").toDate(),
                format: "DD/MM/YYYY HH:mm",
            },
            optPickupEnd: {
                minDate: moment().add(2, "d").add(5.5, "h").toDate(),
                format: "DD/MM/YYYY HH:mm",
            },
            errors: [],
            shouldDisable: false,
            codValueErr: false,
            destTelErr: false,
            totalCourier: [],
            addrOptions: [],
            loading: false,
            loadingOnkgir: false,
            roles: "",
            groups: [],
            pickupTimeOpt: [],
            show: false,
            showLocationInput: false,
            method: "POST",
            defaultInsurance: '1',
            enableInsuranceChange: true,
            verified_ktp: 0,
            form: {
                //pickup_time: moment().add(4, "h").toDate(),
                pickup_via: 1,
                note: "",
                courier_id: "",
                code: "",
                shipper_addr_id: "",
                sender_name: "",
                sender_addr_id: "",
                sender_address: "",
                sender_postcode: "",
                sender_addr_code: "",
                sender_phone: "",
                submit: "",
                order_type: "0",
                pickupend_on: moment().add(2, "d").add(5.5, "h").toDate(),
            },
            formItem: {
                //order
                id: "",
                destName: "",
                destTel: "",
                destCity: "",
                receiver_addr_code: "",
                addr_id: "",
                addr_detail: "",
                item_name: "",
                weight: null,
                length: "",
                width: "",
                height: "",
                item_qty: 1,
                note: "",
                order_code: "",
                orderNo: "",
                no_resi: "",
                nilai_ongkir: 0,
                insurance_type: "1",
                codValue: 0,
                codStatus: true,
                kodepos: '',
                service_code: '',
                ppn_ongkir_inc: 1,
                ppn_ongkir: 0,
                item_value: null,
                statusCodValue: false,
                total_biaya: 0,
                extfield1: ""
            },
            ongkir_satuan: 0,
            submitting1: false,
            submitting2: false,
            og_status: "",
            sender: {},
            droppOffDisabled: true,
            AdminShipper: [],
            role_id: null
        };
    },
    created: function() {
        // alert(this.$route.params.id);
        if (this.$route.params.id) {
            this.loadOrderGroup(this.$route.params.id);
            this.shouldDisable = true;
        } else {
            this.loadAddress();
        }
        var now = moment();
        window.onbeforeunload = this.beforeGo;
        //get data courier for dropdown select
        authFetch("/master/courier/groups")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.totalCourier = js.data;
            });
    },
    watch: {
        $route(to, from) {},
    },
    computed:{
        codDisabled: function() {
			if (this.og_status == 1 || this.verified_ktp == 0) {
				this.formItem.codStatus = false;
				return true;
			} else {
				this.formItem.codStatus = true;
				return false;
			}
		},
        disableSubmit: function() {
            if (this.formItem.nilai_ongkir && this.formItem.codValue){
                return (this.formItem.totalReceived <= 0);
            }
        },
    },
    methods: {
        beforeGo() {
            // check is items was save
            /*if (1) {
                     return "Some task is in progress. Are you sure, you want to close?";
                  }*/
        },
        formatMoney(val) {
            return formatCurrency(val);
        },
        resetFormItem()
        {
            this.formItem = {
                id: "",
                destName: "",
                extfield1: "",
                destTel: "",
                destCity: "",
                addr_id: "",
                addr_detail: "",
                item_name: "",
                no_resi:"",
                weight: null,
                length: "",
                width: "",
                height: "",
				item_qty : 1,
                order_code: '',
                orderNo: "",
                insurance_type: this.defaultInsurance==1,
                note: "",
                codValue: 0,
                nilai_ongkir: 0,
                codStatus: true,
                insurance_fee: "",
                etd_from: this.formItem.etd_from,
                etd_thru: this.formItem.etd_thru,
                totalReceived: this.formItem.totalReceived,
                ins_fee: 0,
                ins_percent: 0,
                ins_min: 0,
                kodepos: '',
                service_code: '',
                ppn_ongkir_inc: 1,
                ppn_ongkir: 0,
                item_value: null,
                statusCodValue: false,
                total_biaya: 0
            };
            this.insurance = null;
        },
        getFormItem()
        {
            const self = this;
            var cod_fee = self.formItem.feeCod / 100;
            var cod_ppn = (self.formItem.ppnCod / 100) * cod_fee;
            var cod_fee_ppn = cod_fee + cod_ppn;
            var calculate_cod_fee = 1 -cod_fee_ppn; 
            var grand_total = Math.ceil((this.formItem.item_value + this.formItem.nilai_ongkir + this.formItem.insurance_fee) / calculate_cod_fee);
            var cod_fee_and_ppn_percent = Math.round(grand_total * self.formItem.fee_cod / 100);
            var cod_fee_and_ppn = Math.round((cod_fee_and_ppn_percent + ( cod_fee_and_ppn_percent * self.formItem.ppnCod / 100)));

            if (this.formItem.codStatus == false || this.formItem.codStatus == true && this.formItem.codValue > 0){
				this.formItem.total_biaya = this.formItem.codStatus ? (cod_fee_ppn + this.formItem.nilai_ongkir + this.formItem.insurance_fee) : (this.formItem.nilai_ongkir + this.formItem.insurance_fee);
			} else {
				this.formItem.total_biaya = cod_fee_ppn + this.formItem.nilai_ongkir + this.formItem.insurance_fee;
			}

            if (this.formItem.codStatus && this.formItem.codValue <= 0) {
                var nilai_cod = grand_total - cod_fee_and_ppn - this.formItem.nilai_ongkir - this.formItem.insurance_fee;

                if(nilai_cod == this.formItem.item_value){
                    this.formItem.codValue = grand_total;
                } else {
                    var grand_total2 = Math.floor((this.formItem.item_value + this.formItem.nilai_ongkir + this.formItem.insurance_fee) / calculate_cod_fee);
                    this.formItem.codValue = grand_total2;
                }
                this.formItem.statusCodValue = false
            } else {
                this.formItem.statusCodValue = true
            }

            var aData = {
                id: this.formItem.id,
                destName: this.formItem.destName,
                extfield1: this.formItem.extfield1,
                destTel: this.formItem.destTel,
                addr_id: this.formItem.addr_id,
                addr_detail: this.formItem.addr_detail,
                item_name: this.formItem.item_name,
                weight: this.formItem.weight,
                length: this.formItem.length,
                width: this.formItem.width,
                height: this.formItem.height,
				item_qty : this.formItem.item_qty,
                insurance_type: this.formItem.insurance_type,
                note: this.formItem.note,
                codValue: this.formItem.codValue,
                item_value: this.formItem.item_value,
                nilai_ongkir: this.formItem.nilai_ongkir,
                codStatus: this.formItem.codStatus,
                order_code: this.formItem.order_code,
                orderNo: this.formItem.orderNo,
                no_resi: this.formItem.no_resi == "" ? null : this.formItem.no_resi,
                action: "",
                ongkir_dasar: this.formItem.ongkir_dasar,
                feeCod: this.formItem.feeCod,
                ppnCod: this.formItem.ppnCod,
                disc: this.formItem.disc,
                insurance_fee: this.formItem.insurance_fee,
                etd_from: this.formItem.etd_from,
                etd_thru: this.formItem.etd_thru,
                totalReceived: this.formItem.totalReceived,
                ins_fee: this.formItem.ins_fee,
                ins_percent: this.formItem.ins_percent,
                ins_min: this.formItem.ins_min,
                service_code: this.formItem.service_code,
                ppn_ongkir_inc: this.formItem.ppn_ongkir_inc,
                ppn_ongkir: this.formItem.ppn_ongkir,
                statusCodValue: this.formItem.statusCodValue,
                total_biaya: this.formItem.total_biaya,
            };
            return aData;
        },
        getDataItem(aData)
        {
            const self = this;

            var cod_fee = self.formItem.feeCod / 100;
            var cod_ppn = (self.formItem.ppnCod / 100) * cod_fee;
            var cod_fee_ppn = cod_fee + cod_ppn;
            var calculate_cod_fee = 1 -cod_fee_ppn; 
            var grand_total = Math.ceil((aData.item_value + aData.nilai_ongkir + aData.insurance_fee) / calculate_cod_fee);
            var cod_fee_and_ppn_percent = Math.round(grand_total * self.formItem.fee_cod / 100);
            var cod_fee_and_ppn = Math.round((cod_fee_and_ppn_percent + ( cod_fee_and_ppn_percent * self.formItem.ppnCod / 100)));

            if (aData.codStatus == false || aData.codStatus == true && aData.statusCodValue == true){
				aData.total_biaya = aData.codStatus ? (aData.nilai_ongkir + cod_fee_ppn + aData.insurance_fee) : (aData.nilai_ongkir + aData.insurance_fee);
			} else {
				aData.total_biaya = aData.nilai_ongkir + cod_fee_ppn + aData.insurance_fee;
			}

            if (aData.codStatus && aData.statusCodValue == false) {
                var nilai_cod = grand_total - cod_fee_and_ppn - aData.nilai_ongkir - aData.insurance_fee;

                if(nilai_cod == this.formItem.item_value){
                    aData.codValue = grand_total;
                } else {
                    var grand_total2 = Math.floor((aData.item_value + aData.nilai_ongkir + aData.insurance_fee) / calculate_cod_fee);
                    aData.codValue = grand_total2;
                }
                aData.statusCodValue = false;
            } else {
                aData.statusCodValue = true;
            }

            return {
                id: aData.id,
                destName: aData.destName,
                extfield1: aData.extfield1,
                destTel: aData.destTel,
                destCity: aData.destCity,
                addr_id: aData.addr_id,
                addr_detail: aData.addr_detail,
                item_name: aData.item_name,
                weight: aData.weight,
                length: aData.length,
                width: aData.width,
                height: aData.height,
                insurance_type: aData.insurance_type == "1" ? true : false,
                note: aData.note,
                codValue: parseInt(aData.codValue),
                item_value: parseInt(aData.item_value),
                item_qty: parseInt(aData.item_qty),
                nilai_ongkir: aData.nilai_ongkir,
                disc: aData.discount,
                order_code: aData.order_code,
                orderNo: aData.orderNo,
                no_resi: aData.no_resi,
                codStatus: aData.codStatus == "1" ? true : false,
                ongkir_dasar: aData.ongkir_dasar * 1,
                feeCod: aData.feeCod,
                ppnCod: aData.ppnCod,
                disc: aData.disc,
                insurance_fee: aData.insurance_fee,
                etd_from: aData.etd_from,
                etd_thru: aData.etd_thru,
                totalReceived: aData.totalReceived,
                ins_fee: aData.ins_fee,
                ins_percent: aData.ins_percent,
                ins_min: aData.ins_min,
                service_code: aData.service_code,
                ppn_ongkir_inc: aData.ppn_ongkir_inc,
                verified_ktp: false,
                statusCodValue: aData.statusCodValue,
                total_biaya: aData.total_biaya,
            };
        },
        kurirChange(event) {
            var items = [];

            if (event.target.selectedOptions[0].title == 1 && this.verified_ktp == true){
                this.droppOffDisabled = false;
            }else{
                this.droppOffDisabled = true;
                this.form.order_type = "0";
            }

            this.tbl
                .api()
                .rows()
                .every(function(rowIdx, tableLoop, rowLoop) {
                    var d = this.data();
                    items.push(d);
                });
            if (items.length > 0) {
                Swal.fire({
                    title: "Konfirmasi",
                    text: "Perubahan jenis kurir mengharuskan entri kembali item pesanan.",
                    showCancelButton: true,
                    confirmButtonText: `Lanjutkan`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.tbl.api().clear().draw();
                        this.prevCourier = event.target.value;
                        this.defaultInsurance = event.target.value==1? '0':'1';
                        this.enableInsuranceChange = event.target.value==1;// only idx
                    } else {
                        this.form.courier_id = this.prevCourier;
                    }
                });
                return;
            }
            this.prevCourier = event.target.value;
            this.defaultInsurance = event.target.value==1? '0':'1';
            this.enableInsuranceChange = event.target.value==1;// only idx
        },
        onChange(event) {
            // if (event.target.value == 1) {
            //     this.form.pickup_via = null;
            // } else {
            // }
            this.form.pickup_via = event.target.value;
        },
        onToggleChange(event) {
            // added event as second arg
            this.formItem.codStatus = event.value;
            this.hitungOngkir();
        },
        setSelected(value) {
            const self = this;
            self.formItem.addr_id = value;
            let s_id = self.sender.addr_id;
            let r_id = self.formItem.addr_id;
            self.loadOngkir(s_id, r_id);
        },
        loadAddress(id) {
            let route;
            if (id) {
                route = "/order/address/" + id;
            } else {
                route = "/order/address/0";
            }
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        this.sender = json.data;
                        this.addrOptions.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                    }
                });
            });
        },
        hitungOngkir: function() {
            const e = this.$refs;
            this.hitungAsuransi();
            this.formItem.totalFeeCod = 0;
            this.formItem.nilai_ongkir = this.formItem.ongkir_dasar - this.formItem.disc;
            if(this.formItem.ppn_ongkir_inc==0)
            {
                // nilai_ongkir = nilai_ongkir*1.01;// +ppn 1%
                this.formItem.nilai_ongkir = (this.formItem.nilai_ongkir * (100 + this.formItem.ppn_ongkir)) / 100;// +ppn 1.1%
            }
            if (this.formItem.codStatus) {
                var codFee = (this.formItem.codValue * this.formItem.feeCod) / 100;
                this.formItem.totalFeeCod = codFee + (codFee * this.formItem.ppnCod) / 100;
                this.formItem.totalReceived =
                    this.formItem.codValue -
                    (this.formItem.nilai_ongkir) - this.formItem.totalFeeCod - 
                    this.formItem.insurance_fee;
            } else {
                this.formItem.totalFeeCod = 0;
                this.formItem.totalReceived = -(this.formItem.nilai_ongkir + this.formItem.insurance_fee);
            }

            if (this.formItem.codValue > 0 && this.formItem.totalReceived <= 0){
                console.log(this.formItem.codValue);
                console.log(this.formItem.totalReceived);
                this.codValueErr = true;
                this.errors.push({
                    message: "Nilai COD harus lebih besar dari pada biaya peingiriman",
                });
                // $(e.formDialog).modal("show");
            } else {
                this.codValueErr = false;
                this.errors = [];
            }
        },
        hitungAsuransi: function() {
            var itemVal = this.formItem.item_value;
            var insFee = 0;
            if (this.formItem.insurance_type) {
                if (itemVal > this.formItem.ins_min) {
                    if (this.formItem.ins_fee){ 
                        insFee = this.formItem.ins_fee;
                    } else if (this.formItem.ins_percent){
                        insFee = (this.formItem.ins_percent * itemVal) / 100;
                    }
                }
            }
            this.formItem.insurance_fee = insFee;
        },
        loadOngkir(s_id, r_id) {
            this.loadingOnkgir = true;
            const self = this;
            let courier_id = self.form.courier_id;
            let route;
            var weight = self.formItem.weight;
            var width = self.formItem.width;
            var height = self.formItem.height;
            var lenght = self.formItem.length;
            if (!weight) weight = 1;
            if (s_id) {
                route = `/order/nilai_ongkir/${courier_id}/${s_id}/${r_id}/${weight}/${width}/${height}/${lenght}`;
            } else {
                route = "/order/nilai_ongkir/854/887";
            }
            if (self.form.shipper_id) {
                route = route + '?shipper_id=' + self.form.shipper_id;
            }
            authFetch(route)
                .then((res) => {
                    if (res.status === 201) {} else if (res.status === 400) {}
                    return res.json();
                })
                .then((js) => {
                    console.log(js);
                    self.loadingOnkgir = false;
                    self.insurance = {
                        percent: js.ins,
                        fee: js.insfee,
                        min: js.insmin,
                    };
                    if (js.success) {
                        let price = js.price == "" ? 0 : js.price;
                        // let reverse = price.toString().split("").reverse().join(""),
                        //   ribuan = reverse.match(/\d{1,3}/g);
                        // ribuan = ribuan.join(".").split("").reverse().join("");

                        self.ongkir_satuan = price;
                        self.formItem.ongkir_dasar = price * 1;
                        self.formItem.feeCod = js.feecod;
                        self.formItem.ppnCod = js.ppn;
                        self.formItem.disc = js.disc;
                        self.formItem.ins_fee = js.insfee;
                        self.formItem.ins_percent = js.ins;
                        self.formItem.ins_min = js.insmin;
                        self.formItem.etd_from = js.etd_from;
                        self.formItem.etd_thru = js.etd_thru;
                        self.formItem.service_code = js.service;
                        self.formItem.ppn_ongkir_inc = js.ppn_ongkir_inc;
                        self.formItem.ppn_ongkir = js.ppn_ongkir;
                        this.hitungOngkir();
                    } else {
                        self.formItem.nilai_ongkir = 0;
                        self.formItem.addr_id = 0;
                        Swal.fire("Proses gagal", `${js.desc}`, "error");
                    }
            });
            this.hitungOngkir();
        },
        onChangeOngkir(event) {
            const self = this;
            self.formItem.weight = event.target.value;
            let ongkir1 = self.ongkir_satuan;
            let nilaiOngkir = ongkir1 * Math.ceil(self.formItem.weight);

            let s_id = self.sender.addr_id;
            let r_id = self.formItem.addr_id;
            self.loadOngkir(s_id, r_id);
        },
        onChangeOngkirPlt() {
            const self = this;
            let s_id = self.sender.addr_id;
            let r_id = self.formItem.addr_id;
            self.loadOngkir(s_id, r_id);
        },
        semuaOngkir() {
            const self = this;
            this.tbl
                .api()
                .rows()
                .every(function(rowIdx, tableLoop, rowLoop) {
                    self.formItem = this.data();

                    let s_id = self.sender.addr_id;
                    let r_id = self.formItem.addr_id;
                    self.loadOngkir(s_id, r_id);

                    var newArray = this.addrOptions.filter((el) => {
                        return el.id == this.formItem.addr_id;
                    });
                    var receiverAddr = newArray[0];
                    let nRow = this.node();
                    var aData = this.getFormItem();
                    aData.destCity = receiverAddr.lokasi;
                    aData.kodepos = receiverAddr.kodepos;
                    aData.receiver_addr_code = receiverAddr.kode;
                    self.tbl.fnUpdate(aData, nRow);
                    $("td .btn.edit", nRow).click();
                    self.loadingOnkgir = false;
                });
        },
        loadOrderGroup(id) {
            authFetch("/order/group/" + id).then((res) => {
                res.json().then((json) => {
                    if (json) {
                        this.form = json;
                        if (this.form.order_type == "1") {
                            this.form.pickup_via = null;
                            this.form.pickup_time = null;
                            this.form.pickupend_on = null;
                        } else {
                            //this.form.pickup_time_id = 1;
                            var pkTime = moment(this.form.pickup_time, "DD-MM-YYYY HH:II:SS");
                            this.form.pickup_time = pkTime.format("YYYY-MM-DD HH:00:00");
                            this.form.pickup_time_str = pkTime.format("DD/MM/YYYY HH:00");
                            this.$refs.daterange.value = this.form.pickup_time_str;
                            
                        }
                        this.og_status = json.og_status;
                        this.loadAddress(json.shipper_addr_id);
                    } else {
                        Swal.fire("Proses gagal", `${js.msg}`, "error");
                        this.$router.push("/order");
                    }
                });
            });
        },
        getImages(name) {
			if (!name) return;
			const fileName = name.toLowerCase();
			return require(`../assets/img/${fileName}.png`);
		},
        verifKtp() {
			this.customSwal.fire({
				html: '<img src="' + this.getImage('verified') + '" class="d-block w-75 m-auto" /><h2 class="swal2-title h4 tex-dark">Verifikasi KTP sekarang untuk dapat<br />menggunakan fitur pesanan COD</h2>',
				showDenyButton: true,
				confirmButtonText: 'Verifikasi KTP',
				denyButtonText: 'Nanti Saja',
			}).then((result) => {
				if (result.isConfirmed) {
                    const e = this.$refs;
					this.$router.push({ path: "/verification"});
                    $(e.formDialog).modal("hide");
				}
			});
		},
        getImage(fileName) {
			return require(`../assets/img/${fileName}.svg`); // the module request
		},
        onSearch(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`+'&cid='+this.form.courier_id).then((res) => {
                    res.json().then((json) => (vm.addrOptions = json.items));
                    loading(false);
                });
            }
        },
        addItem: function() {
            const e = this.$refs;
            this.resetFormItem();
            this.loadAdminShipper();
            $("#update").hide();
            $("#append").show();
            console.log('instype', this.formItem.insurance_type)
            $(e.formDialog).modal("show");
        },
        loadDataUserDropOff() {
        let route = "/order/shipper_tag";
        try {
            authFetch(route).then(res => {
            res.json().then(json => {
                this.verified_ktp = json.info.drop_off
                // console.log("ini ya popo ", json.info.drop_off);
                // console.log("og_status ", this.og_status);
                if (json.success == true && json.info.drop_off == false){
                this.droppOffDisabled = true;
                } else {
                this.droppOffDisabled = false;
                }
            });
            });
            
        } catch (error) {
            
        }
        },
        selectWarehouse: function() {
            this.show = !this.show;
        },
        submitForm: function(ev) {
            this.$gtag.event(`click`, {
                'event_name': 'Buat Pesanan Batch'
            });

            const locationInputRequired = this.form.courier_id == 14 && (this.sender.sender_latitude == null || this.sender.sender_longitude == null);

            if (locationInputRequired) {
              this.showLocationInput = !this.showLocationInput;
              return;
            }

            const self = this;
            this.form.shipper_addr_id = this.sender.id;
            this.form.sender_name = this.sender.senderName;
            this.form.sender_addr_id = this.sender.addr_id;
            this.form.sender_addr_code = this.sender.addr_code;
            this.form.sender_address = this.sender.addr_detail;
            this.form.sender_postcode = this.sender.kodepos;
            this.form.sender_phone = this.sender.senderTel;
            this.form.code = this.sender.code;
            this.form.sender_latitude = this.sender.sender_latitude;
            this.form.sender_longitude = this.sender.sender_longitude;

            var data = this.tbl.getSerialize();
            self.form.id = self.$route.params.id;
            if(ev.submitter){
                self.form.submit = ev.submitter.id;
            }
            else{
                self.form.submit = this.submitType
            }

            var setmethod = self.form.id ? "PUT" : "POST";

            var items = [];
                this.tbl
                    .api()
                    .rows()
                    .every(function(rowIdx, tableLoop, rowLoop) {
                        var d = this.data();
                        items.push(d);
                    });

            var allData = { group: self.form, items: items };

            if (self.form.submit == "draft") {
                this.submitting2 = true;
                // var urlSubmit = "/order/group";
                var urlSubmit = "/order/darft";
                if (self.method == "PUT") urlSubmit = "/order/group/" + self.form.id;

                authFetch(urlSubmit, {
                        method: setmethod,
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(allData),
                    })
                    .then((res) => {
                        if (res.status === 201) {} else if (res.status === 400) {}
                        return res.json();
                    })
                    .then((js) => {
                        this.errors = [];
                        if (!js.success) {
                            this.submitting2 = false;
                            Swal.fire("Proses gagal", `${js.msg}`, "error");
                            for (var key in js.details) {
                                if (js.details.hasOwnProperty(key)) {
                                    this.errors.push(js.details[key]);
                                }
                            }

                            return;
                        }
                        let route = this.$router.resolve({
                            path: "/riwayat/draft-kiriman"
                        });
                        window.open(route.href,"_self");
                        // this.$router.push("/order");
                    });
            } else if (self.form.submit == "submit") {
                Swal.fire({
                    title: "Submit Pesanan?",
                    showCancelButton: true,
                    confirmButtonText: `Submit`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.submitting1 = true;
                        var url = "/order/group";
                        if (self.method == "PUT")
                            url = "/order/group/" + this.$route.params.id;
                        authFetch(url, {
                                method: setmethod,
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(allData),
                            })
                            .then((res) => {
                                this.submitting1 = true;
                                return res.json();
                            })
                            .then((js) => {
                                if (js.success == true) {
                                    this.submitting1 = false;
                                    Swal.fire({
                                        title: "Transaksi Berhasil",
                                        icon: "success",
                                        html: js.data[0].msg,
                                        showCloseButton: true,
                                    });
                                    this.$router.push("/order");
                                } else {
                                    this.submitting1 = false;
                                    if (js.msg && js.msg != 'Total biaya') {
                                        // if (js.msg == 'Total biaya') {
                                        //     this.customSwal.fire({
                                        //             // title: 'Yakin ingin proses pesanan sekarang?',
                                        //             html: '<img src="' + this.getImages('alert-info') + '" class="d-block m-auto"/><br /><h4>Harga produk harus lebih besar atau sama dengan biaya ongkir dan COD fee</h4>',
                                        //             showDenyButton: true,
                                        //             confirmButtonText: 'Ubah Sekarang',
                                        //             denyButtonText: 'Kembali',
                                        //         }).then((result) => {
                                        //         if (result.isConfirmed) {
                                        //             this.showItem();
                                        //         }
                                        //     });
                                        // } else {
                                            Swal.fire("Proses gagal", `${js.msg}`, "error");
                                        // }
                                    } else {
                                        let detailData = '<div style="overflow-y: auto;max-height:150px;" class="border rounded p-2 mt-2 text-muted text-left">';
                                        for (let i = 0; i < js.output.length; i++) {

                                            detailData += "<b>Kode " + js.output[i].no_resi + "</b><br><i>" + js.output[i].msg + "</i><br>";
                                        }

                                        detailData += "</div>";
                                        var finalMsg = '<i class="fa fa-exclamation-triangle"></i> ' + detailData;

                                        Swal.fire({
                                            title: "Proses gagal",
                                            icon: "error",
                                            html: finalMsg,
                                            showCloseButton: true,
                                        });
                                    }
                                }
                            });
                    }
                });
            }
            ev.preventDefault();
        },
        async loadAdminShipper() {
			authFetch('/order/shiper_admin').then(res => {
				res.json().then(json => {
					this.AdminShipper = json;
				});
			});
		},
        handleClick(e) {
            const evt = this.$refs;
            if (e.target.closest("button")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "view") {
                    this.$router.push("/order/detail/" + e.target.dataset.id);
                } else if (btnEl.dataset.action == "editsubmit") {
                    this.$router.push("/order/create/draft/" + e.target.dataset.id);
                    // this.$router.push("/order/edititem/" + e.target.dataset.id);
                } else if (btnEl.dataset.action == "remove") {
                    var orderId = btnEl.dataset.id;
                    if(orderId && this.og_status==1)
                    {
                        authFetch('/order/item/'+orderId, {
                            method: 'DELETE',
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: 'id='+orderId,
                        })
                        .then((res) => {
                            // this.submitting1 = true;
                            return res.json();
                        })
                        .then((js) => {
                            if(js.success)
                            {
                                var nRow = $(e.target).closest("tr").get(0);
                                this.tbl.fnDeleteRow(nRow);
                            }
                        });
                    } else {
                        var nRow = $(e.target).closest("tr").get(0);
                        this.tbl.fnDeleteRow(nRow);
                    }
                } else if (e.target.dataset.action == "edit") {
                    var nRow = $(e.target).closest("tr").get(0);
                    var iRow = this.tbl.fnGetPosition(nRow);
                    var aData = this.tbl.fnGetData(iRow);

                    this.addrOptions.push({
                        id: aData.addr_id,
                        lokasi: aData.destCity + " / " + aData.kecamatan,
                    });
                    this.formItem = this.getDataItem(aData);
                    if (this.formItem.codStatus) {
                        var codFee = (this.formItem.codValue * this.formItem.feeCod) / 100;
                        this.formItem.totalFeeCod = codFee + (codFee * this.formItem.ppnCod) / 100;
                    } else {
                        this.formItem.totalFeeCod = 0;
                    }
                    this.formItem.nRow = nRow;
                    $("#append").hide();
                    $("#update").show();
                    $(evt.formDialog).modal("show");
                } else if (e.target.dataset.action == "submit") {
                    console.log("pipi");
                    const self = this;

                    self.formItem.submit = e.submitter.id;
                    self.formItem.id = e.target.dataset.id;

                    var setmethod = self.formItem.id ? "PUT" : "POST";

                    var allData = { item: self.formItem };

                    if (self.formItem.submit == "submit") {
                        Swal.fire({
                            title: "Submit Pesanan?",
                            showCancelButton: true,
                            confirmButtonText: `Submit`,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                // this.submitting1 = true;
                                var url = "/order/item";
                                if (self.method == "PUT")
                                    url = "/order/item/" + this.$route.params.id;
                                authFetch(url, {
                                        method: setmethod,
                                        headers: {
                                            Accept: "application/json",
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify(allData),
                                    })
                                    .then((res) => {
                                        // this.submitting1 = true;
                                        return res.json();
                                    })
                                    .then((js) => {
                                        if (js.success) {
                                            Swal.fire(
                                                "Proses Berhasil",
                                                `total: ${js.total} <br>
                                                data: <br>
                                                data: ${js.msg}`,
                                                "success"
                                            );
                                            this.$router.push(
                                                "/order/edit/" + self.formItem.group_id
                                            );
                                        } else {
                                            // this.submitting1 = false;
                                            Swal.fire("Proses gagal", `${js.msg}`, "error");
                                        }
                                    });
                            }
                        });
                    }
                }
                return false;
            }
        },
        saveItem: function(ev) {
            var btnId = this.submitItemType;
            if(ev.submitter){
                btnId = ev.submitter.id;
            }
            ev.preventDefault();
            if (btnId == "append") {
                this.appendItem(ev);
            } else if (btnId == "update") {
                this.updateItem(ev);
            }
        },
        appendItem: function(ev) {
            const e = this.$refs;
            this.errors = [];
            if (!this.formItem.destTel) {
                return false;
            }
            this.codValueErr = false;
            var newArray = this.addrOptions.filter((el) => {
                return el.id == this.formItem.addr_id;
            });
            var receiverAddr = newArray[0];
            if (!receiverAddr) return;
            if (!this.formItem.weight) this.formItem.weight = 1;
            let nRow = this.tbl.fnAddRow(this.formItem);
            var aData = this.getFormItem();
            aData.destCity = receiverAddr.lokasi;
            aData.kodepos = receiverAddr.kodepos;
            aData.receiver_addr_code = receiverAddr.kode;
            this.tbl.fnUpdate(aData, nRow);
            $("td .btn.edit", nRow).click();
            this.resetFormItem();
            $(e.formDialog).modal("hide");
        },
        updateItem: function(ev) {
            const e = this.$refs;
            this.errors = [];
            if (!this.formItem.destTel) {
                return false;
            }
            // if (this.formItem.codStatus && (!this.formItem.codValue || this.formItem.codValue < 1000 || this.formItem.codValue > 2500000)) {
            //     this.codValueErr = true;
            //     this.errors.push({
            //         message: "Nilai COD harus diisi antara Rp 1.000 - Rp 2.500.000",
            //     });
            //     $(e.formDialog).modal("show");
            // } else if (!this.formItem.codStatus && !this.formItem.codValue) {
            //     this.codValueErr = true;
            //     this.errors.push({
            //         message: "Nilai harus diisi",
            //     });
            //     $(e.formDialog).modal("show");
            // } else if (this.formItem.totalReceived < 0 && this.formItem.codStatus) {
            //     this.codValueErr = true;
            //     this.errors.push({
            //         message: "Nilai COD tidak mencukupi biaya pengiriman",
            //     });
            //     $(e.formDialog).modal("show");
            // } else {
                this.codValueErr = false;
                var newArray = this.addrOptions.filter((el) => {
                    return el.id == this.formItem.addr_id;
                });
                var receiverAddr = newArray[0];
                let nRow = this.formItem.nRow;
                var aData = this.getFormItem();
                aData.destCity = receiverAddr.lokasi;
                aData.kodepos = receiverAddr.kodepos;
                aData.receiver_addr_code = receiverAddr.kode;
                this.tbl.fnUpdate(aData, nRow);
                $("td .btn.edit", nRow).click();
                this.resetFormItem();
                $(e.formDialog).modal("hide");
            // }
        },
        tutupItem: function(ev) {
            const e = this.$refs;
            var aData = this.getFormItem();
            if (aData.addr_id != '' && aData.statusCodValue == false){
                var newArray = this.addrOptions.filter((el) => {
                    return el.id == this.formItem.addr_id;
                });
                var receiverAddr = newArray[0];
                let nRow = this.formItem.nRow;
                var aData = this.getFormItem();
                aData.destCity = receiverAddr.lokasi;
                aData.kodepos = receiverAddr.kodepos;
                aData.receiver_addr_code = receiverAddr.kode;
                aData.statusCodValue = false;
                this.tbl.fnUpdate(aData, nRow);
                $("td .btn.edit", nRow).click();
                this.resetFormItem();
                $(e.formDialog).modal("hide");
            } else{
                $(e.formDialog).modal("hide");
            }
        },
    },
    components: {
        vSelect,
        maskedInput,
        CurrencyInput,
        Warehouse
    },
    mounted() {
        const e = this.$refs;
        const self = this;
        var now = moment();
        var minDate = now.startOf('hour').add(1, 'h');
        this.customSwal = Swal.mixin({
			customClass: {
				// popup: 'w-fit',
				title: 'h4 tex-dark',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary w-49 font-weight-bold py-3',
				denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-3',
				cancelButton: 'btn btn-primary btn-block font-weight-bold py-3',
			},
			buttonsStyling: false
		});

        this.role_id = 	parseInt(auth.user().role_id);

        if (now.format('H') >= 20) {
            minDate = minDate.add(1, "d").startOf('date').add(8, 'h');
        }
        if (!this.$route.params.id) {
            this.form.pickup_time = minDate.format('YYYY-MM-DD HH:mm:00');
        }
        new Daterangepicker(this.$refs.daterange, {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: false,
            timePicker24Hour: false,
            // timePickerIncrement: 60,
            startDate: minDate,
            minDate: minDate,
            locale: {
                // format: 'DD/MM/YYYY HH:mm'
                format: 'DD/MM/YYYY'
            }
        }, function(d1, d2) {
            // self.form.pickup_time = d1.format('YYYY-MM-DD HH:mm:00');
            if (moment(d1.format("YYYY-MM-DD")).valueOf() == moment(moment().format("YYYY-MM-DD")).valueOf()) {
                self.form.pickup_time = d1.format("YYYY-MM-DD HH:mm:ss");
            } else {
                self.form.pickup_time = d1.format("YYYY-MM-DD 08:00:00");
            }
        });

        this.tbl = createTable(e.tableorder, {
            title: "",
            serverSide: false,
            ajax: "/order",
            paramData: function(d) {
                if (self.$route.params.id) {
                    // d.pickup = self.og_status == 0 ? 0 : 1;
                    //d.pickup = 0;
                    d.group_id = self.$route.params.id;
                } else {
                    //d.pickup = -1;
                    d.group_id = 0; //self.$route.params.id;
                }
            },
            selectedRowClass: "",
            frame: false,
            displayLength: 100,
            lengthChange: false,
            filter: false,
            columns: [
                { data: "id", visible: false },
                { data: "orderNo", visible: false },
                { data: "order_code", visible:self.$route.params.id>0},
                { data: "no_resi", visible:self.$route.params.id>0 },
                { data: "destName" },
                { data: "destTel" },
                { data: "destCity" },
                { data: "weight" },
                { data: "item_qty" },
                {
                    data: "codValue",
                    render: function(data, type, row, meta) {
                        return formatCurrency(data);
                    },
                },
                { data: "codStatus" },
                { data: "extfield1" },
                { data: "addr_id" },
                { data: "item_name", visible: false },
                { data: "length", visible: false },
                { data: "width", visible: false },
                { data: "height", visible: false },
                { data: "insurance_type", visible: false },
                { data: "note", visible: false },
                { data: "nilai_ongkir", visible: false },
                { data: "addr_id", visible: false },
                { data: "addr_detail", visible: false },
            ],
            rowCallback: function(row, data) {
                self.og_status = data.status;
                let statusCod = "";
                if (data.codStatus == true) {
                    statusCod = true;
                } else {
                    statusCod = false;
                }

                var strAction =
                    `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-warning" data-action="editsubmit" data-id="` +
                    data.orderNo +
                    `"><i class="fas fa-edit"></i> Edit</button>
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
                    data.id +
                    `"><i class="fas fa-trash"></i> Hapus</button>
          <button type="submit" class="btn btn-sm btn-outline-success d-none" data-action="submit" id="submit" data-id="` +
                    data.id +
                    `"><i class="fas fa-check"></i> Submit</button>
                  </div>`;

                var table = $("#tblorder").DataTable();
                if (self.$route.params.id) {
                    //table.column(1).visible(true);
                    //table.column(2).visible(true);

                    if (statusCod == true) {
                        $("td:eq(8)", row).html(
                            `<span class="badge badge-success">YA</span>`
                        );
                        //$("td:eq(6)", row).html(data.codValue);
                    } else {
                        $("td:eq(8)", row).html(
                            `<span class="badge badge-warning">TIDAK</span>`
                        );
                        //$("td:eq(6)", row).html(data.item_value);
                    }

                    if (data.status == 1 && (!data.no_resi || data.no_resi == '' || data.no_resi == null)) {
                        $("td:eq(9)", row).html(strAction);
                    } else if (data.status == 1 && data.no_resi != "") {
                        $("td:eq(9)", row).html(
                            `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="` +
                            data.orderNo +
                            `"><i class="fas fa-eye"></i> Lihat detail</button>
              </div>`
                        );
                    } else {
                        $("td:eq(9)", row).html(
                            `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-warning" data-action="edit" data-id="` +
                            data.id +
                            `"><i class="fas fa-edit"></i> Edit</button>
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
                            data.id +
                            `"><i class="fas fa-trash"></i> Hapus</button>
                  </div>`
                        );
                    }
                } else {
                    //table.column(1).visible(false);
                    //table.column(2).visible(false);

                    if (statusCod == true) {
                        $("td:eq(6)", row).html(
                            `<span class="badge badge-success">YA</span>`
                        );
                    } else {
                        $("td:eq(6)", row).html(
                            `<span class="badge badge-warning">TIDAK</span>`
                        );
                    }

                    $("td:eq(8)", row).html(
                        `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-warning" data-action="edit" data-id="` +
                        data.id +
                        `"><i class="fas fa-edit"></i> Edit</button>
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
                        data.id +
                        `"><i class="fas fa-trash"></i> Hapus</button>
                  </div>`
                    );
                }
            },
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>',
        });
        $("input.mask").each((i,ele)=>{
            let clone=$(ele).clone(false);
            clone.attr("type","number");
			clone.css("border-top-left-radius", "0.375rem");
			clone.css("border-bottom-left-radius", "0.375rem");
            let ele1=$(ele);
            clone.val(Number(ele1.val()).toLocaleString("en"));
            $(ele).after(clone);
            $(ele).hide();
            clone.mouseenter(()=>{
                ele1.show();
                clone.hide();
            })

			// if (ele1.val() == 0 || ele1.val() == undefined || ele1.val() == null || ele1.val() == ""){
				ele1.show();
                clone.hide();
			// } else {
				setInterval(()=>{
					let newv=Number(ele1.val()).toLocaleString("en");
					if(clone.val()!=newv){
						clone.val(newv);
					}
				},10)

				$(ele).mouseleave(()=>{
					$(clone).show();
					$(ele1).hide();
				})
			// }
        });
        this.loadDataUserDropOff();
    },
};
</script>
<style scoped>
</style>