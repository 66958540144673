<template>
<section class="content">
    <div class="container-fluid">
    <div>
        <!--Shipper Shortcut Tools-->
        <div class="card" v-if="isShipper">
        <div class="card-body">
            <div class="row justify-content-between">
                <div class="col-sm-auto">
                    <div class="text-secondary">
                        <h2 class="float-left"></h2>
                        <div class="mb-3">
                            <h4 class="m-0"> Halo <strong>{{ username }} !</strong></h4>
                            <div>Ayo kirimkan paketmu sekarang</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-sm-12 row">
                <div class="col-sm-3">
                <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="hxf hxf-delivery text-info" style="font-size: 24px;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Total Kiriman</h6>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.total_kiriman }}</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-3">
                <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="far fa-check-circle fa-2x" style="color: lawngreen;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Sukses</h6>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.rasio_sukses }}%</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-3">
                <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="fas fa-undo-alt fa-2x" style="color: red;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Retur</h6>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.rasio_retur }}%</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-3">
                <div class="btn-group btn-sm btn-block">
                    <button
                    type="button"
                    class="btn btn-primary btn-lg dropdown-toggle dropdown-icon"
                    data-toggle="dropdown"
                    >
                    <i class="fas fa-plus"></i> Buat Pesanan
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                    <router-link to="/order/create/single" class="dropdown-item">
                        <i class="fas fa-edit"></i> Input Manual
                    </router-link>
                    <router-link v-if="!isMobile" to="/order/create" class="dropdown-item">
                        <i class="fas fa-edit"></i> Input Manual Batch
                    </router-link>
                    <router-link to="/order/import" class="dropdown-item">
                        <i class="fas fa-upload"></i> Import
                    </router-link>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        <!--FILTER-->
        <div class="row justify-content-end mt-4">
        <div class="col-md-4 col-xs-12">
            <div class="row">
                <div class="col-12 mb-2">
                    <div class="input-group">
                    <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                    />
                    <div class="input-group-append">
                    <div class="input-group-text" style="background-color: #fff">
                        <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        ></span>
                        <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                    </div>
                </div>
                <div class="col-12">
                    <select class="form-control" @change="kurirChange($event)">
                        <option value="#" disabled selected>Pilih kurir</option>
                        <option v-for="courier in totalCourier" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id">
                            {{ courier.nama_kurir }}
                        </option>
                        <option value="0">Semua</option>
                    </select>
                </div>
            </div>
        </div>
        </div>

        <!--STATISTIC-->
        
        <div id="_sectionStatus">
            <div class="row mt-3">
                <!--PERSIAPAN-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="fas fa-box"></i></h5>
                        <h5 class="info-box-text">Persiapan</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { draft: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-draft text-primary" style="font-size: 24px;"></i><h5 class="info-text">Draft</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.draft }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { rpick: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-request-pickup text-primary" style="font-size: 24px;"></i><h5 class="info-text">Request Pickup</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.reqPickup }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { canc: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fa fa-ban text-primary" style="font-size: 24px;"></i><h5 class="info-text">Cancel Pesanan</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.cancel }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
                <!--PENGIRIMAN-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="fas fa-car"></i></h5>
                        <h5 class="info-box-text">Pengiriman</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { delgo: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-delivery text-primary" style="font-size: 24px;"></i><h5 class="info-text">Pengiriman</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.delivery }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { deler: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fa fa-exclamation-triangle text-primary" style="font-size: 24px;"></i><h5 class="info-text">Problem</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.problem }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { delok: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-delivered text-primary" style="font-size: 24px;"></i><h5 class="info-text">Telah Diterima</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.delivered }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { retgo: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-returned text-primary" style="font-size: 24px;"></i><h5 class="info-text">Proses Retur</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.returngo }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
                <!--RETUR-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="fas fa-undo-alt"></i></h5>
                        <h5 class="info-box-text">Retur</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { retok: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-returned text-primary" style="font-size: 24px;"></i><h5 class="info-text">Diserahkan</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.returned }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { retacc: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-returned text-primary" style="font-size: 24px;"></i><h5 class="info-text">Diterima</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.retReceived }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
                <!--TIDAK BERGERAK-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
                        <h5 class="info-box-text">Tidak Bergerak</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { nmove5: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-nochange text-primary" style="font-size: 24px;"></i><h5 class="info-text">>5 Hari</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.notMove5 }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { nmove10: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-nochange text-primary" style="font-size: 24px;"></i><h5 class="info-text">>10 Hari</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.notMove10 }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { pbreach: 1, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-nochange text-primary"></i><h5 class="info-text">>14 hari</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{dataStatus.notMove14}}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <!-- <router-link
                        :to="{
                            name: 'Riwayat',
                            params: {filter: { rpick: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        > -->
                        <div class="info-box mb-3 vertical-center box-border row" v-if="false">
                            <div class="row" style="padding-left: 10px;">
                            <i class="far fa-edit fa-2x text-primary"></i><h5 class="info-text">Claim Breach</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">0</h5>
                            </div>
                            </div>
                        </div>
                        <!-- </router-link> -->
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="_sectionTicket">
            <div class="row mt-3">
                <!--TICKET-->
                <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
                    <div class="col-12 col-sm-2 cat">
                    <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                        <h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
                        <h5 class="info-box-text">Ticket & Claim</h5>
                    </div>
                    </div>
                    <div class="col-12 col-sm-10 row margin-0">
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'List Ticket',
                            params: {filter:{ open: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fas fa-inbox text-primary" style="font-size: 24px;"></i><h5 class="info-text">Open</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.open }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'List Ticket',
                            params: {filter: { closed: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="fas fa-check-double text-primary" style="font-size: 24px;"></i><h5 class="info-text">Closed</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.closed }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <router-link
                        :to="{
                            name: 'List Claim',
                            params: {filter: { retclm: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                        }"
                        >
                        <div class="info-box mb-3 vertical-center box-border row">
                            <div class="row" style="padding-left: 10px;">
                            <i class="hxf hxf-rtk text-primary" style="font-size: 24px;"></i><h5 class="info-text">Claim</h5>
                            </div>
                            <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                            <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                                <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.retClaim }}</h5>
                            </div>
                            </div>
                        </div>
                        </router-link>
                        <!-- /.info-box -->
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="_sectionChart">
            <div class="row mt-3">
                <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="overlay-wrapper">
                    <div class="info-box mb-3">
                <div class="overlay" v-if="loading3"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
                        <canvas ref="myChart"></canvas>
                    </div>
                </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="overlay-wrapper">
                <div class="info-box">
                    <div class="overlay" v-if="loading3"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
                    <canvas ref="myChart2"></canvas>
                </div>
                </div>
                </div>
            </div>
        </div>

    </div>
    
    <!-- fix for small devices only -->
    <div class="clearfix hidden-md-up"></div>
    <div class="p-2"></div>
    </div>
    <Affiliator :show="showAffiliate" />
    <ModalDashboard :show="showModalDashboard" />
</section>
</template>
<script>
import Daterangepicker from "daterangepicker";
import $ from "jquery";
import Chart from "chart.js";
import { authFetch, auth, formatCurrency, isMobile } from "@/libs/hxcore";
import ModalDashboard from "@/dialog/ModalDashboard";
import Affiliator from "@/dialog/Affiliator";
import Alert from "@/dialog/Alert";
import moment from "moment";
import Vue from 'vue';
import "daterangepicker/daterangepicker.css";
var myChart = null;
var myChart2 = null;
export default {
    name: "Home",
    data: function () {
        return {
          showModalDashboard: false,
          isShipper: false,
          roles: "",
          dateStart: moment().day(-31).format("YYYY-MM-DD"),
          dateEnd: moment().add(2, 'd').format("YYYY-MM-DD"),
          allTicket: {},
          dataStatus: {},
          dataFinance: {},
          dataCashback: {},
          totalCourier: [],
          courier_id: '',
          url: window.$apiUrl,
          dataCourierPie: {
            type: "pie",
            data: [],
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Data Courier",
                },
              },
            },
          },
          dataCourierBar: {
            type: "bar",
            data: [],
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Data",
                },
              },
            },
          },
          dt: {
            start: "",
            end: "",
          },
          infoBar: [],
          loading: false,
          loading2: false,
          loading3: false,
          loading4: false,
          loading5: false,
          loadedStatus: false,
          loadedTicket: false,
          loadedChart: false,
          loadedFinance: false,
          loadedCashback: false,
          check: false,
          showAffiliate: false,
          infoVerifikasi: "",
          styleVerifikasi: {},
          disableVerifikasi: true,
        };
    },
    components: {
        ModalDashboard,
        Affiliator,
        Alert,
    },
    created: function () {
        this.roles = this.$route.params.roles;
        var info = auth.user();
        if (info) {
          this.username = info.display_name;
          this.isShipper = info.role_id > 10;
        }

        if(Vue.config.appHost == 'xpress')
            this.check = false;
        else this.check = true;

        authFetch("/master/courier/groups")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.totalCourier = js.data;
            });
        this.loadStatusCashback();
        this.loadStatusOrder();
    },
    computed: {
        isReady: function () {
          return !this.loading;
        },
        isMobile: function(){return isMobile();},
    },
    methods: {
        // handleScroll: ,
        kurirChange(event) {
            let id = event.target.value;
            this.courier_id = id;
            this.loadStatusCashback();
            this.loadStatusOrder();
            this.loadedTicket = false;
            this.loadedChart = false;
            // this.loadedFinance = false;
            this.loadedCashback = false;
        },
        loadStatusOrder() {
        var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
        this.loading = true;
        var search = new URLSearchParams(params);
        authFetch(`/dashboard/statistic?${search}`).then((res) => {
            res.json().then((json) => {
            this.loading = false;
            if (json.success) {
                this.dataStatus = json.status;
                this.dataStatus.rasio_sukses = json.status.rasio_sukses.toFixed(1);
                this.dataStatus.rasio_retur = json.status.rasio_retur.toFixed(2);
                $('.loading-overlay').removeClass('show');
            }
            });
        });
        },
        loadStatusTicket: function () {
            // if (this.loadedTicket == true) {
                // return;
            // } else {
                this.loadedTicket = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
                this.loading2 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/statistic_tickets?${search}`)
                    .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    }
                    return res.json();
                    })
                    .then((js) => {
                    this.loading2 = false;
                    this.allTicket = js.data;
                    });
            // }
        },
        loadStatusChart: function () {
            if (this.loadedChart == true) {
                return;
            } else {
                this.loadedChart = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
                this.loading3 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/statistic_chart?${search}`).then((res) => {
                    res.json().then((json) => {
                    this.loading3 = false;
                    if (json.success) {
                        this.dataCourierPie.data = json.pieChart;
                        this.dataCourierBar.data = json.barChart;
                        myChart.update();
                        myChart2.update();
                    }
                    });
                });
            }
        },
        loadStatusCashback: function () {
            // if (this.loadedCashback == true) {
            //     return;
            // } else {
                this.loadedCashback = true;
                var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
                this.loading5 = true;
                var search = new URLSearchParams(params);
                authFetch(`/dashboard/cashback?${search}`).then((res) => {
                    res.json().then((json) => {
                    this.loading5 = false;
                    // if (json.success) {
                        this.dataCashback = json;
                    // }
                    });
                });
            // }
        },
        formatMoney(val) {
            return formatCurrency(val);
        },
        filterRoles(role) {
            return this.roles.filter(n=>n===role).length===0?false:true; 
        },
    },
    mounted() {
        function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
        if (getCookie('eidmubarak1443') != 'true') {
            this.showModalDashboard = false;
        }

        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', '//embed.typeform.com/next/embed.js')
        document.head.appendChild(recaptchaScript)
        let self = this;

        //daterange all
        new Daterangepicker(
            this.$refs.daterange,
            {
                startDate: moment().day(-31),
                endDate: moment().add(2, 'd'),
                locale: {
                    format: 'DD/MM/YYYY'
                },
            },
            function (d1, d2) {
                self.dateStart = d1.format("YYYY-MM-DD");
                self.dateEnd = d2.format("YYYY-MM-DD");
                self.loadStatusCashback();
                self.loadStatusOrder();
                self.loadedTicket = false;
                self.loadedChart = false;
                // self.loadedFinance = false;
                self.loadedCashback = false;
            }
        );

        var ctx = this.$refs.myChart.getContext("2d");
        var ctx2 = this.$refs.myChart2.getContext("2d");
        myChart = new Chart(ctx, this.dataCourierPie);
        myChart2 = new Chart(ctx2, this.dataCourierBar);


        window.addEventListener('scroll', function (event) {
            // const self = this;
            if ($('#_sectionTicket').length == 0) {
                return;
            }
            var hT2 = $('#_sectionTicket').offset().top,
                hH2 = $('#_sectionTicket').outerHeight(),
                hT3 = $('#_sectionChart').offset().top,
                hH3 = $('#_sectionChart').outerHeight(),
                // hT5 = $('#_sectionCashback').offset().top,
                // hH5 = $('#_sectionCashback').outerHeight(),
                wH = $(window).height(),
                wS = $(this).scrollTop();

            // console.log(hT2, hH2, wH, wS);
            if (wS > (hT2+hH2-wH)){
                //load ticket
                self.loadStatusTicket();
            } 
            if (wS > (hT3+hH3-wH)){
                //load chart
                self.loadStatusChart();
            }
            // if (wS > (hT5+hH5-wH)){
            //     //load cashback
            //     // self.loadStatusCashback();
            // }
        });
        // $(window).scroll();
    },
};
</script>
<style>
#nominal{
  text-align: right;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /*text-align: center;*/
}

router-link {
  background-color: black;
  color: black;
}

a, a:hover {
  color: black;
}

.text-center-v {
  align-self: center;
}

.text-padding-12 {
  padding-top: 14px;
  overflow-wrap: anywhere;
}

@media (min-width: 300px) and (max-width: 1023px) {
  .text-right {
    text-align: left !important;
  }

  .text-padding-12 {
    padding-top: 0px;
    overflow-wrap: anywhere;
  }
}

.text-light {
  color: white;
}
.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.box-border {
  border: 1.5px #C4C4C4 solid;
}
.info-box .info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}
.text-higher {
    font-size: large;
}
.info-text{
    color: initial;
    padding: 3px;
}
.cat {
  text-align: center;
  vertical-align: middle;
  background-color: rgb(41, 47, 65);
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .cat {
    border-radius: 0px;
  }
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .info-box .cat {
    border-radius: 0px;
  } 
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
@media (max-width: 320px) {
  .info-box .cat {
    border-radius: 0px;
  } 
  .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .rasio {
    margin-bottom: 12px;
  }
}
</style>