<template>
<section class="content">
    <div class="container-fluid">
    <!-- <div class="alert fade show" role="alert" v-if="infoVerifikasi" 
        :style="[ { cursor : !disableVerifikasi ? 'pointer' : 'default' }, styleVerifikasi ]"
        @click="!disableVerifikasi ? $router.push('/verification') : {}">
        <i class="fi fi-rr-exclamation"></i> {{ infoVerifikasi }}
    </div> -->
    <Alert page="dashboard" position="top" />
    <div class="alert alert-success fade show" role="alert" v-bind:class="{ 'skeleton-item': loading }" v-if="true">
        <div class="row justify-content-between">
            <div class="col-auto my-2">
                <div v-if="!dataCashback.success">
                    <i class="fa fa-1x fa-shopping-cart"></i>  Kamu belum kirim paket di bulan ini, Klik tombol <b>Buat Pesanan</b> untuk mulai mengirim paket
                </div>
                <div v-if="dataCashback.success">
                    <i class="fas fa-1x fa-trophy"></i>  Sejak pakai BosCOD, kamu udah hemat <b>Rp {{ formatMoney(dataCashback.total) }}</b> di periode ini
                </div>
            </div>
            <div class="col-auto">
                <button type="button" href="javascript:" v-on:click="showAffiliate = !showAffiliate" class="btn btn-light text-success"><b>Ajak Temanmu </b><i class="fa fa-chevron-right"></i></button>
            </div>
        </div>
    </div>
    <div>
        <!--Shipper Shortcut Tools-->
        <div class="card" v-if="isShipper">
        <div class="card-body">
            <div class="row justify-content-between">
                <div class="col-sm-auto">
                    <div class="text-secondary">
                        <h2 class="float-left"></h2>
                        <div class="mb-3">
                            <h4 class="m-0"> Halo <strong>{{ username }} !</strong></h4>
                            <div>Ayo kirimkan paketmu sekarang</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-auto ml-auto">
                    <div class="input-group">
                    <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                    />
                    <div class="input-group-append">
                    <div class="input-group-text" style="background-color: #fff">
                        <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        ></span>
                        <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="hxf hxf-delivery text-info" style="font-size: 24px;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Total Kiriman</h6>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.total_kiriman }}</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-3">
                <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="far fa-check-circle fa-2x" style="color: lawngreen;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Sukses</h6>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.rasio_sukses }}%</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-3">
                <div class="info-box vertical-center box-border row rasio" v-bind:class="{ 'skeleton-item': loading }" style="min-height: 50px; margin-right: 8px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="fas fa-undo-alt fa-2x" style="color: red;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Retur</h6>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.rasio_retur }}%</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-auto ml-auto">
                <div class="btn-group btn-sm btn-block px-0">
                    <button
                    type="button"
                    class="btn btn-primary btn-lg dropdown-toggle dropdown-icon"
                    data-toggle="dropdown"
                    >
                    <i class="fas fa-plus"></i> Buat Pesanan
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                    <router-link to="/order/create/single" class="dropdown-item">
                        <i class="fas fa-edit"></i> Input Manual
                    </router-link>
                    <router-link v-if="!isMobile" to="/order/create" class="dropdown-item">
                        <i class="fas fa-edit"></i> Input Manual Batch
                    </router-link>
                    <router-link to="/order/import" class="dropdown-item">
                        <i class="fas fa-upload"></i> Import
                    </router-link>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>

        <div class="card" v-if="isShipper">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-4 my-2">
                    <div class="rounded text-center p-3" style="border: 1.5px #C4C4C4 solid">
                        <img src="../assets/img/dashboard/Persiapan.svg">
                        <div class="h4 mt-2 font-weight-bold">
                            Persiapan
                        </div>
                        <div class="text-secondary">
                            Berisikan status paket antara lain, Draft, Request Pickup, Cancel Pesanan
                        </div>
                        <button data-toggle="dropdown" class="btn btn-block btn-outline-primary mt-3 dropdown-toggle">Lihat Selengkapnya</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { draft: 1 } },
                            }" class="dropdown-item">Draft</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { rpick: 1 } },
                            }" class="dropdown-item">Request Pickup</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { canc: 1 } },
                            }" class="dropdown-item">Cancel Pesanan</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 my-2">
                    <div class="rounded text-center p-3" style="border: 1.5px #C4C4C4 solid">
                        <img src="../assets/img/dashboard/Pengiriman.svg">
                        <div class="h4 mt-2 font-weight-bold">
                            Pengiriman
                        </div>
                        <div class="text-secondary">
                            Berisikan status paket antara lain, Pengiriman, Telah Diterima, Proses Retur
                        </div>
                        <button data-toggle="dropdown" class="btn btn-block btn-outline-primary mt-3 dropdown-toggle">Lihat Selengkapnya</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { delgo: 1 } },
                            }" class="dropdown-item">Pengiriman</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { deler: 1 } },
                            }" class="dropdown-item">Problem</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { delok: 1 } },
                            }" class="dropdown-item">Telah Diterima</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { retgo: 1 } },
                            }" class="dropdown-item">Proses Retur</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 my-2">
                    <div class="rounded text-center p-3" style="border: 1.5px #C4C4C4 solid">
                        <img src="../assets/img/dashboard/Retur.svg">
                        <div class="h4 mt-2 font-weight-bold">
                            Retur
                        </div>
                        <div class="text-secondary">
                            Berisikan status paket antara lain, Retur Diserahkan, Retur Diterima
                        </div>
                        <button data-toggle="dropdown" class="btn btn-block btn-outline-primary mt-3 dropdown-toggle">Lihat Selengkapnya</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { retok: 1 } },
                            }" class="dropdown-item">Diserahkan</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { retacc: 1 } },
                            }" class="dropdown-item">Diterima</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 my-2">
                    <div class="rounded text-center p-3" style="border: 1.5px #C4C4C4 solid">
                        <img src="../assets/img/dashboard/Tidak Bergerak.svg">
                        <div class="h4 mt-2 font-weight-bold">
                            Tidak Bergerak
                        </div>
                        <div class="text-secondary">
                            Berisikan status paket antara lain, Lebih dari 5 Hari, Lebih dari 10 Hari, Potential Breach
                        </div>
                        <button data-toggle="dropdown" class="btn btn-block btn-outline-primary mt-3 dropdown-toggle">Lihat Selengkapnya</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { nmove5: 1 } },
                            }" class="dropdown-item">>5 Hari</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { nmove10: 1 } },
                            }" class="dropdown-item">>10 Hari</router-link>
                            <router-link :to="{
                                name: 'Riwayat',
                                params: {filter: { pbreach: 1 } },
                            }" class="dropdown-item">Potential Breach</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 my-2">
                    <div class="rounded text-center p-3" style="border: 1.5px #C4C4C4 solid">
                        <img src="../assets/img/dashboard/Ticket _ Claim.svg">
                        <div class="h4 mt-2 font-weight-bold">
                            Ticket & Claim
                        </div>
                        <div class="text-secondary">
                            Berisikan status paket antara lain, Ticket Open, Ticket Close, Claim
                        </div>
                        <button data-toggle="dropdown" class="btn btn-block btn-outline-primary mt-3 dropdown-toggle">Lihat Selengkapnya</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{
                                name: 'List Ticket',
                                params: {filter:{ open: 1 } },
                            }" class="dropdown-item">Open</router-link>
                            <router-link :to="{
                                name: 'List Ticket',
                                params: {filter: { closed: 1 } },
                            }" class="dropdown-item">Closed</router-link>
                            <router-link :to="{
                                name: 'List Claim',
                                // params: {filter: { retclm: 1 } },
                            }" class="dropdown-item">Claim</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    
    <!-- fix for small devices only -->
    <div class="clearfix hidden-md-up"></div>
    <div class="p-2"></div>
    </div>
    <Affiliator :show="showAffiliate" />
</section>
</template>
<script>
import Daterangepicker from "daterangepicker";
import $ from "jquery";
import { authFetch, auth, formatCurrency, isMobile } from "@/libs/hxcore";
import Affiliator from "@/dialog/Affiliator";
import Alert from "@/dialog/Alert";
import moment from "moment";
import Vue from 'vue';
import "daterangepicker/daterangepicker.css";
export default {
    name: "Home",
    data: function () {
        return {
            isShipper: false,
            roles: "",
            dateStart: moment().day(-31).format("YYYY-MM-DD"),
            dateEnd: moment().add(2, 'd').format("YYYY-MM-DD"),
            dataStatus: {},
            dataCashback: {},
            totalCourier: [],
            courier_id: '',
            url: window.$apiUrl,
            dt: {
            start: "",
            end: "",
            },
            infoBar: [],
            loading: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            loadedStatus: false,
            loadedTicket: false,
            loadedChart: false,
            loadedFinance: false,
            loadedCashback: false,
            check: false,
            showAffiliate: false,
            infoVerifikasi: "",
            styleVerifikasi: {},
            disableVerifikasi: true,
        };
    },
    components: {
        Affiliator,
        Alert,
    },
    created: function () {
        this.roles = this.$route.params.roles;
        var info = auth.user();
        if (info) {
            this.username = info.display_name;
            this.isShipper = info.role_id > 10;
        }

        if(Vue.config.appHost == 'xpress')
            this.check = false;
        else this.check = true;
        this.loadStatusCashback();
        this.loadStatusOrder();
    },
    computed: {
        isReady: function () {
            return !this.loading;
        },
        isMobile: function(){return isMobile();},
    },
    methods: {
        loadStatusOrder() {
        var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
        this.loading = true;
        var search = new URLSearchParams(params);
        authFetch(`/dashboard/statistic_performance?${search}`).then((res) => {
            res.json().then((json) => {
            this.loading = false;
            if (json.success) {
                this.dataStatus = json.status;
                this.dataStatus.rasio_sukses = json.status.rasio_sukses.toFixed(1);
                this.dataStatus.rasio_retur = json.status.rasio_retur.toFixed(2);
            }
            });
        });
        },
        loadStatusCashback: function () {
            this.loadedCashback = true;
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
            this.loading5 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/cashback?${search}`).then((res) => {
                res.json().then((json) => {
                this.loading5 = false;
                    this.dataCashback = json;
                });
            });
        },
        formatMoney(val) {
            return formatCurrency(val);
        },
        filterRoles(role) {
            return this.roles.filter(n=>n===role).length===0?false:true; 
        },
    },
    mounted() {
        let self = this;
        new Daterangepicker(
            this.$refs.daterange,
            {
                startDate: moment().day(-31),
                endDate: moment().add(2, 'd'),
                locale: {
                    format: 'DD/MM/YYYY'
                },
            },
            function (d1, d2) {
                self.dateStart = d1.format("YYYY-MM-DD");
                self.dateEnd = d2.format("YYYY-MM-DD");
                self.loadStatusOrder();
            }
        );
    },
};
</script>
<style>
#nominal{
    text-align: right;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    /*text-align: center;*/
}

router-link {
    background-color: black;
    color: black;
}

a, a:hover {
    color: black;
}

.text-center-v {
    align-self: center;
}

.text-padding-12 {
    padding-top: 14px;
    overflow-wrap: anywhere;
}

@media (min-width: 300px) and (max-width: 1023px) {
    .text-right {
    text-align: left !important;
    }

    .text-padding-12 {
    padding-top: 0px;
    overflow-wrap: anywhere;
    }
}

.text-light {
    color: white;
}
.vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.box-border {
    border: 1.5px #C4C4C4 solid;
}
.info-box .info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
}
.text-higher {
    font-size: large;
}
.info-text{
    color: initial;
    padding: 3px;
}
.cat {
    text-align: center;
    vertical-align: middle;
    background-color: rgb(41, 47, 65);
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
}
@media (min-width: 481px) and (max-width: 767px) {
    .cat {
    border-radius: 0px;
    }
    .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
    }
    .rasio {
    margin-bottom: 12px;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .info-box .cat {
    border-radius: 0px;
    } 
    .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
    }
    .rasio {
    margin-bottom: 12px;
    }
}
@media (max-width: 320px) {
    .info-box .cat {
    border-radius: 0px;
    } 
    .margin-0 {
    margin-left: 0px;
    margin-right: 0px;
    }
    .rasio {
    margin-bottom: 12px;
    }
}
</style>